import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PlaylistDetailTable from "components/Table/PlaylistDetailTable";
import MusicTableForPlaylist from "components/Table/MusicTableForPlaylist";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'

import Pagination from '@material-ui/lab/Pagination';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        color: 'white'
    }
};

const useStyles = makeStyles(styles);

export default function Playlists()
{
    const [data, setData] = useState({
        totalPages: 0,
        songs: []
    })
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const classes = useStyles();
    const alert = useAlert()
    const { id } = useParams()

    const paginate = (array, pagination, page) =>
    {
        return array.slice((page - 1) * pagination, page * pagination);
    }

    const getPlaylist = async () =>
    {
        try
        {
            const res = await WebService.get(`playlist/${id}`)

            setData({
                totalPages: Math.ceil(res.data.playlist.songs.length / 20),
                songs: res.data.playlist.songs,
                name: res.data.playlist.name
            })

            setTableData(paginate( res.data.playlist.songs, 20, page))
        }
        catch (err)
        {
            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    const handleAddToPlaylist = async (songId) =>
    {

        try
        {
            const res = await WebService.post(`playlist/${id}/add`, { songId: songId })

            setData({
                totalPages: Math.ceil(res.data.playlist.songs.length / 20),
                songs: res.data.playlist.songs,
                name: res.data.playlist.name
            })

            setTableData(paginate( res.data.playlist.songs, 20, page))

            alert.success('Çalma listesine eklendi')
        }
        catch (err)
        {
            alert.error('Bilinmeyen hata')

            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }

    }

    const handleRemoveFromPlaylist = async (songId) =>
    {
        try
        {
            const res =await WebService.post(`playlist/${id}/remove`,  { songId: songId })

            setData({
                totalPages: Math.ceil(res.data.playlist.songs.length / 20),
                songs: res.data.playlist.songs,
                name: res.data.playlist.name
            })

            setTableData(paginate( res.data.playlist.songs, 20, page))

            alert.success('Çalma listesinden çıkarıldı')
        }
        catch (err)
        {
            alert.error('Bilinmeyen hata')

            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    useEffect(() =>
    {
        getPlaylist()
    }, [])

    return (
        <>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>Çalma Listesi - {data.name} </h4>
                            </Box>
                        </CardHeader>
                        <CardBody>

                            <PlaylistDetailTable
                                tableHeaderColor="primary"
                                tableHead={["Ad", "Sanatcı", "Süre", "Yükleme Tarihi", ""]}
                                tableData={tableData}
                                handleRemoveFromPlaylist={handleRemoveFromPlaylist}
                            />
                            <Box mt={2} display='flex' justifyContent='center'>
                                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
                            </Box>
                        </CardBody>
                    </Card>
                </GridItem>
                <Musics handleAddToPlaylist={handleAddToPlaylist} songs={data.songs} />
            </GridContainer>
        </>
    );
}

function Musics({handleAddToPlaylist,songs})
{
    const [data, setData] = useState({
        totalPages: 0,
        musics: []
    })
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const classes = useStyles();
    const alert = useAlert()

    const getAll = async () =>
    {
        {

            try
            {
                const res = await WebService.get('media', {
                    params: {
                        page: page, search: search
                    }
                })

                setData(res.data)
            }
            catch (err)
            {
                if (err.response)
                {
                    return console.log(err.response)
                }

                console.log(err)
            }
        }
    }


    useEffect(() =>
    {
        setPage(1)
        getAll()
    }, [search])

    useEffect(() =>
    {
        getAll()
    }, [page])

    return (
        <>

            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <h4 className={classes.cardTitleWhite}>Müzikler</h4>
                            <CustomInput
                                value={search}
                                labelText="Ara"
                                id="ara"
                                inputProps={{
                                    onChange: (e) => setSearch(e.target.value),
                                    style: { color: 'white' }
                                }}
                                formControlProps={{
                                    fullWidth: false,
                                    style: { margin: 'unset' }
                                }}
                            />
                        </Box>
                    </CardHeader>
                    <CardBody>
                        <MusicTableForPlaylist
                            tableHeaderColor="primary"
                            tableHead={["Ad", "Sanatcı", "Süre", "Yükleme Tarihi", ""]}
                            tableData={data.musics}
                            handleAddToPlaylist={handleAddToPlaylist}
                            songs={songs}
                        />
                        <Box mt={2} display='flex' justifyContent='center'>
                            <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
                        </Box>

                    </CardBody>
                </Card>
            </GridItem>

        </>
    );
}

