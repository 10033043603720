import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { mediaUrl } from 'utils/urls'
import store from '../../store'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';

export const RenderEditor = ({ input, meta, ...rest }) =>
{

  return (
    <div style={ (meta.touched &&meta.error) ? { border: '1px solid red' } : {}} >
      <Editor
        onBlur={()=>input.onBlur()}
        value={input.value}
        onEditorChange={(value, editor) => input.onChange(value)}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          images_upload_handler: image_upload_handler,
          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help'
        }}

        
      />
      {
        ( meta.touched &&meta.error) &&
        <span style={{ color: 'red' }}>{meta.error}</span>
      }
    </div>

  )
}

async function image_upload_handler(blobInfo, success, failure, progress)
{
  const aws = store.getState().auth.aws
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });
  
  try
  {
    const params = {
      ACL: 'public-read',
      Body: blobInfo.blob(),
      Bucket: S3_BUCKET,
      Key: 'images/' + blobInfo.filename()
    };

    const command = new PutObjectCommand(params)

    await client.send(command)

    success(mediaUrl + 'images/' + blobInfo.filename())
  }
  catch (err)
  {
    console.log(err)
    failure('Image upload failed');
  }
};