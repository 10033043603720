import React, { useState, useEffect,useCallback } from 'react'
import WebService from 'services/WebService'
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { Box, Modal, Paper, Button, ButtonGroup,makeStyles } from '@material-ui/core'
import {apiUrl} from 'utils/urls'
import { useAlert } from 'react-alert'

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
        padding: 10
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    modal:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    modalPaper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2),
      },
}));

const GeneralTab = (props) =>
{
    const styles = useStyles()
    const [lang, setLang] = useState('tr')
    const [nameSpace, setNameSpace] = useState('home')
    const [data, setData] = useState('')
    const [dirty, setDirty] = useState(false)
    const [modal, setModal] = useState(false)
    const [navValues, setNavValues] = useState({})
    const alert = useAlert()

    const getData = useCallback(async (lang, ns) =>
    {
        setData('')
        try
        {
            const res = await WebService.get(`${apiUrl}/localization/locale.json?lang=${lang}&ns=${ns}`)

            setDirty(false)
            setData(res.data)
        }
        catch (err)
        {
            if (err.response)
            {
                return console.log(err.response)
            }
            console.log(err)
        }
    }, [])

    const saveData = async () =>
    {
        try
        {
            await WebService.post('localization', {
                lang: lang,
                ns: nameSpace,
                data: data
            })
            setDirty(false)

            alert.success('Çeviri Kaydedildi')
        }
        catch (err)
        {
            alert.error('Bilinmeyen Hata')
            if (err.response)
            {
                return console.log(err.response)
            }
            console.log(err)
        }
    }

    const handleChange = (json) =>
    {
        setDirty(true)
        setData(json)
    }

    const handleLanguageChange = (lang) =>
    {
        if (dirty)
        {
            setNavValues({
                lang: lang
            })
            return setModal(true)
        }
        setLang(lang)
    }

    const handleNameSpaceChange = (ns) =>
    {
        if (dirty)
        {
            setNavValues({
                ns: ns
            })
            return setModal(true)
        }
        setNameSpace(ns)
    }

    const handleContinue = () =>
    {
        setModal(false)
        if (navValues.lang) setLang(navValues.lang)
        if (navValues.ns) setNameSpace(navValues.ns)
        setNavValues({})
    }

    const handleSave = () =>
    {
        setModal(false)
        saveData()
        if (navValues.lang) setLang(navValues.lang)
        if (navValues.ns) setNameSpace(navValues.ns)
        setNavValues({})
    }

    useEffect(() =>
    {
        getData(lang, nameSpace)
    }, [lang, nameSpace, getData])


    return (
        <>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                className={styles.modal}
            >
                <Paper className={styles.modalPaper}>
                    <Box>
                        Devam ederseniz yaptığınız değişiklikler kaybolacak. Devam etmeden önce değişiklikleriniz kaydetmek istermisiniz ?
                </Box>
                    <Box mt={2} display='flex' justifyContent='space-between'>
                        <Button onClick={() => handleSave()} size='small' variant="contained" color="primary">Kaydet</Button>
                        <Button onClick={() => handleContinue()} size='small' variant="contained" color="primary">Kaydetmeden Devam Et</Button>
                    </Box>
                </Paper>
            </Modal>
            <Box mt={2} display='flex' justifyContent='space-between'>
                <ButtonGroup variant="contained" color="primary">
                    <Button disabled={lang == 'tr' ? true : false} onClick={() => handleLanguageChange('tr')}>TR</Button>
                    <Button disabled={lang == 'en' ? true : false} onClick={() => handleLanguageChange('en')}>EN</Button>
                </ButtonGroup>
                <Button onClick={() => saveData()} disabled={!dirty} variant="contained" color="primary">Kaydet</Button>
            </Box>
            <Box mt={2}>
                <ButtonGroup variant="contained" color="primary">
                    <Button disabled={nameSpace == 'common' ? true : false} onClick={() => handleNameSpaceChange('common')}>Genel</Button>
                    <Button disabled={nameSpace == 'home' ? true : false} onClick={() => handleNameSpaceChange('home')}>Ana Sayfa</Button>
                    <Button disabled={nameSpace == 'plak' ? true : false} onClick={() => handleNameSpaceChange('plak')}>Plaklar</Button>
                    <Button disabled={nameSpace == 'plak-detay' ? true : false} onClick={() => handleNameSpaceChange('plak-detay')}>Plak-Detay</Button>
                    <Button disabled={nameSpace == 'magaza' ? true : false} onClick={() => handleNameSpaceChange('magaza')}>Mağaza</Button>
                    <Button disabled={nameSpace == 'magaza-detay' ? true : false} onClick={() => handleNameSpaceChange('magaza-detay')}>Mağaza Detay</Button>
                    <Button disabled={nameSpace == 'blog' ? true : false} onClick={() => handleNameSpaceChange('blog')}>Blog</Button>
                    <Button disabled={nameSpace == 'blog-detay' ? true : false} onClick={() => handleNameSpaceChange('blog-detay')}>Blog Detay</Button>
                    <Button disabled={nameSpace == 'canli' ? true : false} onClick={() => handleNameSpaceChange('canli')}>Canlı</Button>
                    <Button disabled={nameSpace == 'hakkimizda' ? true : false} onClick={() => handleNameSpaceChange('hakkimizda')}>Hakkımızda</Button>
                    <Button disabled={nameSpace == 'iletisim' ? true : false} onClick={() => handleNameSpaceChange('iletisim')}>iletisim</Button>
                    <Button disabled={nameSpace == 'kullanici' ? true : false} onClick={() => handleNameSpaceChange('kullanici')}>Kullanıcı</Button>
                    <Button disabled={nameSpace == 'sepet' ? true : false} onClick={() => handleNameSpaceChange('sepet')}>Sepet</Button>
                </ButtonGroup>
            </Box>
            <Box mt={2}>
                {
                    data && (
                        <Editor
                            value={data}
                            history
                            mode='form'
                            onChange={(json) => handleChange(json)}
                        />
                    )
                }

            </Box>
        </>
    )
}

export default GeneralTab