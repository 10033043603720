import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { DateTime } from "luxon";

const useStyles = makeStyles(styles);

export default function ParticipantTable(props)
{

  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key}/>
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data })
{
  const classes = useStyles();

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell style={{cursor:'pointer'}} className={classes.tableCell} >
        {data.email}
      </TableCell>
      <TableCell  style={{cursor:'pointer'}} className={classes.tableCell} >
      {DateTime.fromISO(data.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }
      </TableCell>
    </TableRow>
  )
}