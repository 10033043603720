import React, {useState, useCallback, useEffect,useRef  } from 'react'
import { Box, Paper, makeStyles, CircularProgress, Button,Modal,Backdrop,Fade } from '@material-ui/core'
import { reduxForm, Field,formValueSelector,initialize  } from 'redux-form'
import { RenderTextField } from 'components/FormFields/'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {useSelector,useDispatch} from 'react-redux'
import {required} from 'utils/validation'
import WebService from 'services/WebService'
import {useHistory} from 'react-router-dom'
import "react-image-crop/dist/ReactCrop.css";
import { RenderRadio } from 'components/FormFields';
import { useAlert } from 'react-alert';


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};
const useStyles = makeStyles(styles);

const ContactPage = (props) =>
{
    const classes = useStyles();
    const state = useSelector(state => state)
    const [sending,setSending] = useState(false)
    const history = useHistory()
    const alert = useAlert()
    const dispatch = useDispatch()

    const sendRequest = async() => {
        if(!props.valid) return
        setSending(true)
        try {
            const selector = formValueSelector('contactPageForm')
            const values = selector(state, 'address','phone','fax','isOpen')
        
            const data = {
                address:values.address,
                phone:values.phone,
                fax:values.fax,
                isOpen:values.isOpen == 'true' ? true : false,
            }

            const res = await WebService.post('contact-page',data)
            
            alert.success('İşlem başarılı.')
            setSending(false)
        }
        catch(err)
        {
            setSending(false)
            if(err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    const getData = async () => {
        try {
            const res = await WebService.get('contact-page')
            dispatch(initialize('contactPageForm',{
                address:res.data.contactPage.value.address,
                phone:res.data.contactPage.value.phone,
                fax:res.data.contactPage.value.fax,
                isOpen:res.data.contactPage.value.isOpen ? 'true' : 'false',
            }))

        } catch(err) {

        }
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <GridContainer>
            <GridItem xs={12} sm={9} >
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>İletişim Sayfası</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer spacing={4}>
                            <GridItem xs={12} sm={12} >
                                <Paper style={{ padding: 15, paddingTop: 5, }}>
                                    <h4>Genel</h4>
                                    <Box>
                                        <Field name="address" component={RenderTextField} label="Adres" variant="outlined"  multiline rows={4} fullWidth validate={[required]} />
                                    </Box>
                                    <Box mt={4}>
                                        <Field name="phone" component={RenderTextField} label="Telefon" variant="outlined" fullWidth validate={[required]} />
                                    </Box>
                                    <Box mt={4}>
                                        <Field name="fax" component={RenderTextField} label="Fax" variant="outlined" fullWidth validate={[required]} />
                                    </Box>
                                    <Box mt={4}>
                                        <Field name="isOpen" component={RenderRadio} label="Kafe Açık" variant="outlined" fullWidth  fieldSet={[{value:'true',label:'Açık'},{value:'false',label:'Kapalı'}]} />
                                    </Box>
                                </Paper>
                                <Box mt={6} display='flex' justifyContent='flex-end'>
                                    <Button disabled={!props.valid || sending} variant='contained' onClick={() => sendRequest()}>Kaydet</Button>
                                </Box>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default reduxForm({
    form: 'contactPageForm'
})(ContactPage)