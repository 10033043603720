import React, { useState } from 'react';
import {Box,Typography} from '@material-ui/core'
import CreatableSelect from 'react-select/creatable';

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '100%'
    }),

}

export const RenderCreatableSelect = ({
    label,
    input,
    meta: { touched, invalid, error },
    dataSet,
    ...rest
}) =>
{
    const [value, setValue] = useState('')

    return (
        <Box display='flex' flexDirection='column' width='100%'>
            <Typography style={{marginBottom:10}} variant='body1'>{label}:</Typography>
            <CreatableSelect
                isClearable
                onChange={(value) => input.onChange(value)}
                onInputChange={(value) => setValue(value)}
                value={input.value}
                options={dataSet}
                {...rest}
                styles={customStyles}
            />
        </Box>

    );
}
