import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'

import
{
    authSlice
} from '../slices/authSlicer'

const reducers = combineReducers({
    auth: authSlice.reducer,
    form: formReducer
})

export const store = configureStore({
    reducer: reducers
})

export default store