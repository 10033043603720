import React, { useCallback, useState, useEffect,useRef  } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, Paper,Modal,Backdrop,Fade } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import SlideTable from "components/Table/SlideTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Pagination from '@material-ui/lab/Pagination';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {useSelector} from 'react-redux'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';



const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    color: 'white'
  }
};

const useStyles = makeStyles(styles);

export default function SlideShow()
{
  const [data, setData] = useState({
    totalPages:0,
    slides:[]
  })
  const [page,setPage] = useState(1)
  const classes = useStyles();
  const alert = useAlert()

  const getAll = async () =>
  {
    {

      try
      {
        const res = await WebService.get('slide',{
          params:{
            page:page
          }
        })

        setData(res.data)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  const handleDelete = async(id) => {
    try {
      const res = await WebService.delete(`slide/${id}`)

      alert.success('Slide silindi')
      getAll()
    }
    catch(err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  useEffect(() =>
  {
    getAll()
  }, [page])

  return (
    <>
      <NewSlide getAll={getAll} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Slide Gösterisi</h4>
              </Box>
            </CardHeader>
            <CardBody>
              <SlideTable
                tableHeaderColor="primary"
                tableHead={["","Yükleme Tarihi", ""]}
                tableData={data.slides}
                handleDelete={handleDelete}
              />
              <Box mt={2} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e,value) => setPage(value)} />
              </Box>
             
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const useUploadMediaStyles = makeStyles((theme) => ({
  paper: {
      display: 'flex',
      flexDirection: 'column',
  },
  cropArea: {
      width: 'auto',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
          maxWidth: 600,
      },
      [theme.breakpoints.up('md')]: {
          maxWidth: 900
      }
  },
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
}))

function NewSlide({ getAll })
{
  const styles = useUploadMediaStyles()
  const [uploading, setUploading] = useState(false)
  const alert = useAlert()
  const [openCrop, setOpenCrop] = useState(false)

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 80, aspect: 16 / 9 });

  const aws = useSelector(state => state.auth.aws)
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });

  const onLoad = useCallback(img =>
  {
      imgRef.current = img;
  }, []);

  const onDrop = useCallback((acceptedFiles) =>
  {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () =>
      {
          setUpImg({ name: acceptedFiles[0].name, file: reader.result })
          setOpenCrop(true)
      }

      reader.readAsDataURL(acceptedFiles[0])
  }, [])

  const endCrop = () =>
  {
      const image = new Image();
      image.src = upImg.file

      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / imgRef.current.width;
      const scaleY = image.naturalHeight / imgRef.current.height;
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext("2d");

      const sx = crop.x * scaleX
      const sy = crop.y * scaleY
      const sWidth = crop.width * scaleX
      const sHeight = crop.height * scaleY
      const width = crop.width * scaleX
      const height = crop.height * scaleY

      ctx.drawImage(
          image,
          sx,
          sy,
          sWidth,
          sHeight,
          0,
          0,
          width,
          height
      )

      const reader = new FileReader()
      canvas.toBlob(blob =>
      {
          reader.readAsDataURL(blob)

          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () =>
          {
              setOpenCrop(false)
              upload(dataURLtoFile(reader.result, `${Date.now()}.jpg`))
          }
      })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/png,image/jpeg',
    onDrop,
    maxFiles: 1
  })

  const upload = async (file) =>
  {
    setUploading(true)
    try
    {

      const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: 'slides/' + file.name
      };

      const command = new PutObjectCommand(params)

      await client.send(command)

      await WebService.post('slide', {
        url: 'slides/' + file.name
      })

      alert.success('Slide yüklendi')
      getAll()

    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
    finally
    {
      setUploading(false)
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ height: 300 }} profile>
            <CardBody style={{ marginTop: 0 }} profile>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  uploading ?
                    (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                      <CircularProgress />
                    </Box>)
                    :
                    (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                      <PublishIcon />
                      <p>Resmi bu alana bırakın yada bilgisayarınızdan seçin</p>
                    </Box>
                    )
                }
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal
                aria-labelledby='Media Zone'
                aria-describedby='Media Crop'
                open={openCrop}
                onClose={() => setOpenCrop(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={styles.modal}
            >
                <Fade in={openCrop}>
                    <Paper className={styles.paper}>
                        <Box p={2}>
                            <ReactCrop
                                src={upImg?.file}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={c => setCrop(c)}
                                className={styles.cropArea}
                                imageStyle={{ maxHeight: '85vh' }}
                            />
                        </Box>
                        <Box p={2} pt={0} display='flex' justifyContent='center'>
                            <Button variant='contained' onClick={endCrop} >Tamam</Button>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
    </div>
  );
}

const dataURLtoFile = (dataurl, filename) =>
{
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--)
    {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage
}