import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MusicTable from "components/Table/MusicTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'
import Pagination from '@material-ui/lab/Pagination';
import Notes from 'components/Notes/Notes'

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        color: 'white'
    }
};

const useStyles = makeStyles(styles);

export default function Note()
{
    const [data, setData] = useState({
        totalPages: 0,
        notes: []
    })
    const [page, setPage] = useState(1)
    const [note,setNote] = useState('')
    const classes = useStyles();
    const alert = useAlert()

    const getAll = async () =>
    {
        try
        {
            const res = await WebService.get('note', {
                params: {
                    page: page
                }
            })

            setData(res.data)
        }
        catch (err)
        {
            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    const newNote = async() => {
        try
        {
            const res = await WebService.post('note', {content:note })

            getAll()
        }
        catch (err)
        {
            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    useEffect(() =>
    {
        getAll()
    }, [page])

    return (
        <>
         <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>Yeni Not</h4>
                            </Box>
                        </CardHeader>
                        <CardBody>
                            <Box display='flex' justifyContent='space-between'>
                            <TextField style={{maxWidth:500,width:'100%'}} label='Not' placeholder='not...' onChange={(e) => setNote(e.target.value)} value={note} />
                            <Button onClick={() => newNote()}>Oluştur</Button>
                            </Box>
                           
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem  xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>Notlar</h4>
                            </Box>
                        </CardHeader>
                        <CardBody>
                            <Notes
                                data={data.notes}
                                getAll={getAll}
                            />
                            <Box mt={2} display='flex' justifyContent='center'>
                                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
                            </Box>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}