import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, FormControlLabel, Switch } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import StreamTable from "components/Table/StreamTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'

import Pagination from '@material-ui/lab/Pagination';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    color: 'white'
  }
};

const useStyles = makeStyles(styles);

export default function Stream()
{
  const [data, setData] = useState({
    totalPages: 0,
    streams: []
  })
  const [page, setPage] = useState(1)
  const [isLive,setIsLive] = useState(false)
  const classes = useStyles();
  const alert = useAlert()

  const getStreamStatus = async() => {
    try {
      const res = await WebService.get(`/stream/live`);

      setIsLive(res.data.live)
    }
    catch(err)
    {
      console.log(err)
    }
  }

  const toggleStream = async() => {
    try {
      const res = await WebService.post(`/stream/live`,{
        live:!isLive
      });

      setIsLive(res.data.live)

      alert.success(`Yayın ${res.data.live ? 'açıldı' : 'kapatıldı'}`)
    }
    catch(err){
      console.log(err)
    }
  }

  const getAll = async () =>
  {
    {

      try
      {
        const res = await WebService.get('stream', {
          params: {
            page: page
          }
        })

        setData(res.data)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  const handleDelete = async (id) =>
  {
    try
    {
      const res = await WebService.delete(`stream/${id}`)

      alert.success('Yayın silindi')
      getAll()
    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  useEffect(() =>
  {
    getAll()
    getStreamStatus()
  }, [page])

  return (
    <>
      <NewStream getAll={getAll} isLive={isLive} toggleStream={toggleStream} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Yayın Listesi</h4>
              </Box>
            </CardHeader>
            <CardBody>
              <StreamTable
                tableHeaderColor="primary"
                tableHead={["Url", "Oluşturma Tarihi", ""]}
                tableData={data.streams}
                handleDelete={handleDelete}
              />
              <Box mt={2} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
              </Box>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

function NewStream({ getAll,isLive,toggleStream })
{
  const [values, setValues] = useState({ url: '' })
  const [sending, setSending] = useState(false)
  const alert = useAlert()
  const classes = useStyles();

  const newStream = async () =>
  {
    if (!values.url) return
    setSending(true)
    try
    {

      const res = await WebService.post('stream', {
        url: values.url
      })

      console.log(res)
      alert.success('Yayın oluşturuldu')
      getAll()
      setValues({ url: null })

    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
    finally
    {
      setSending(false)
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Canlı Yayın</h4>
              </Box>
            </CardHeader>
            <CardBody>
              <FormControlLabel
                control={
                  <Switch
                    checked={isLive}
                    onChange={()=> toggleStream()}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={isLive ? 'Canlı Yayın Aktif' : 'Canlı Yayın Kapalı'}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Yeni Yayın</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    value={values.name}
                    labelText="Yayın Adresi"
                    id="name"
                    inputProps={{
                      onChange: (e) => setValues({ ...values, url: e.target.value })
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: 'flex-end' }}>
              <Button disabled={sending} color="primary" onClick={() => newStream()} >Oluştur</Button>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
