import React from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

export const RenderRadio = ({ input, label,fieldSet }) => (
    <div>
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup value={input.value} onChange={input.onChange}>
                {
                    fieldSet.map((field,i) => (
                        <FormControlLabel key={i} value={field.value} control={<Radio />} label={field.label} />
                    ))
                }
            </RadioGroup>
        </FormControl>
    </div>
)