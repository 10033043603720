import React, { useState, useEffect } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Notes from "components/Notes/Notes";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import { bugs, website, server } from "variables/general.js";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import axiosInstance from "services/WebService";
import ContactTable from "components/Table/ContactTable";

const useStyles = makeStyles(styles);

export default function Dashboard()
{
  const classes = useStyles();
  const [customerCount, setCustomerCount] = useState(0)
  const [profit, setProfit] = useState(0)
  const [orders, setOrders] = useState(0)
  const [notes, setNotes] = useState([])
  const [contacts, setContacts] = useState([])
  const [activityInfo, setActivityInfo] = useState({ activity: {}, totalParticipant: 0 })

  const getOrdersRequest = async (cursor) =>
  {
    try
    {
      const res = await WebService.post('graphql', {
        query: orderQuery,
        variables: {
          "after": cursor ? cursor : null
        }
      })

      return res.data.orders
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getOrders = async () =>
  {
    try
    {
      let data = {}
      let final = []

      data = await getOrdersRequest()
      final.push(...data.edges)

      while (data.pageInfo.hasNextPage)
      {
        data = await getOrdersRequest(data.edges[data.edges.length - 1].cursor)
        final.push(...data.edges)
      }

      setOrders(final.length)
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getCustomerCountRequest = async (cursor) =>
  {
    try
    {
      const res = await WebService.post('graphql', {
        query: customerQuery,
        variables: {
          "after": cursor ? cursor : null
        }
      })

      return res.data.customers
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getCustomerCount = async () =>
  {
    try
    {
      let data = {}
      let final = []

      data = await getCustomerCountRequest()
      final.push(...data.edges)

      while (data.pageInfo.hasNextPage)
      {
        data = await getCustomerCountRequest(data.edges[data.edges.length - 1].cursor)
        final.push(...data.edges)
      }

      setCustomerCount(final.length)
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getTotalProfitRequest = async (cursor) =>
  {
    try
    {
      const res = await WebService.post('graphql', {
        query: profitQuery,
        variables: {
          "after": cursor ? cursor : null
        }
      })

      return res.data.orders
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getTotalProfit = async () =>
  {
    try
    {
      let data = {}
      let final = []

      data = await getTotalProfitRequest()
      final.push(...data.edges)

      while (data.pageInfo.hasNextPage)
      {
        data = await getTotalProfitRequest(data.edges[data.edges.length - 1].cursor)
        final.push(...data.edges)
      }

      let total = 0

      final.forEach(order => total = total + parseFloat(order.node.totalPriceSet.presentmentMoney.amount))

      setProfit(total)
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getActivityInfo = async () =>
  {
    try
    {
      const res = await axiosInstance.get('activity/admin/latest')
      console.log(res.data)
      setActivityInfo(res.data)
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getNotes = async () =>
  {
    try
    {
      const res = await WebService.get('note', {
        params: {
          page: 1,
          pagination: 10
        }
      })

      setNotes(res.data.notes)
    }
    catch (err)
    {
      console.log(err)
    }
  }

  const getContacts = async () =>
  {
    {

      try
      {
        const res = await WebService.get('contact/lastTen')

        setContacts(res.data.contacts)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  useEffect(() =>
  {
    getCustomerCount()
    getOrders()
    getTotalProfit()
    getNotes()
    getActivityInfo()
    getContacts()
  }, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>group</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Toplan Müşteri Sayısı</p>
              <h3 className={classes.cardTitle}>
                {customerCount} <small>Müşteri</small>
              </h3>
            </CardHeader>
            <CardFooter stats>

            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>sell</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Bekleyen Siparişler</p>
              <h3 className={classes.cardTitle}>
                {orders} <small>Sipariş</small>
              </h3>
            </CardHeader>
            <CardFooter stats>

            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>monetization_on</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Toplam Kazanç</p>
              <h3 className={classes.cardTitle}>{profit} TL</h3>
            </CardHeader>
            <CardFooter stats>

            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon><LocalActivityIcon /></Icon>
              </CardIcon>
              <p className={classes.cardCategory}>{activityInfo.activity.title}</p>
              <h3 className={classes.cardTitle}>{activityInfo.totalParticipant} Kayıtlı Katılımcı</h3>
            </CardHeader>
            <CardFooter stats>

            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Notlar"
            headerColor="primary"
            tabContent={
              <Notes
                data={notes}
                getAll={getNotes}
              />
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Bekleyen İletişim İstekleri"
            headerColor="primary"
            tabContent={
              <ContactTable
              tableHead={["Ad Soyad", "E-posta", "İstek Tarihi"]}
                tableData={contacts}
              />
            }
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

const customerQuery = `
query customers($after:String){
  customers(first:250,after:$after){
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}`

const orderQuery = `
query orders($after:String){
  orders(first:250,query:"fulfillment_status:unfulfilled",after:$after){
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}`

const profitQuery = `
query orders($after:String){
  orders(first:250,after:$after){
    edges {
      cursor
      node {
        totalPriceSet {
          presentmentMoney {
            amount
          }
        }
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}`