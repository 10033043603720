import axios from 'axios'
import { logout } from '../utils/logout'
import { apiUrl } from '../utils/urls'
import { store } from '../store'

const axiosInstance = axios.create(
    {
        baseURL: apiUrl,
        timeout: 0,
    }
)

axiosInstance.interceptors.request.use(function (config)
{
    const auth = store.getState().auth

    if (auth.isAuth)
    {
        config.headers.Authorization = auth.token
    }

    return config;
});

axiosInstance.interceptors.response.use(function (response)
{
    return response;
}, function (err)
{
    if (err.response.status === 401 && err.response.data.forceLogout)
    {
        console.log(err.response)
        return logout()

    }

    throw err
})

export default axiosInstance