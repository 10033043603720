import React, { useState, useEffect, useCallback } from "react";
// @material-ui/core components
import { makeStyles, Avatar, Grid, Box, CircularProgress } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import avatar from "assets/img/faces/marc.jpg";
import { useParams } from 'react-router-dom'
import { mediaUrl } from 'utils/urls'
import { useSelector, useDispatch } from 'react-redux'
import { reduxForm, Field, formValueSelector, initialize } from 'redux-form'
import { RenderTextField, RenderEditor, RenderCreatableSelect } from 'components/FormFields/'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useDropzone } from 'react-dropzone'
import ImageIcon from '@material-ui/icons/Image';
import { setUser } from 'slices/authSlicer'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';



const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",

    }
};

const useStyles = makeStyles(styles);

function UserDetail(props)
{
    const { id } = useParams()
    const [data, setData] = useState({ user: {} })
    const [image, setImage] = useState()
    const classes = useStyles();
    const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch()
    const disableControls = user.class == 'root' || user._id == id ? false : true
    const [dirty, setDirty] = useState(false)
    const [sending, setSending] = useState(false)
    const state = useSelector(state => state)

    const getProfile = async () =>
    {

        try
        {
            const res = await WebService.get(`auth/user/${id}`)

            setData(res.data)
            setImage()
            dispatch(initialize('userDetail', {
                displayName: res.data.user.displayName,
                username: res.data.user.username,
                email: res.data.user.email,
                class: res.data.user.class
            }))
        }
        catch (err)
        {
            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }

    }

    const updateProfile = async () =>
    {
        setSending(true)
        try
        {
            const selector = formValueSelector('newBlog')
            const values = selector(state, 'displayName', 'username', 'email')

            const data = {
                displayName: values.title,
                username: values.desc,
                email: values.body,
                profilePicture: image
            }

            const res = await WebService.put(`auth/user/${id}`, data)

            if (res.data.error)
            {
                return alert.error(res.data.message)
            }

            setData(res.data)
            setImage()
            dispatch(initialize('userDetail', {
                displayName: res.data.user.displayName,
                username: res.data.user.username,
                email: res.data.user.email,
                class: res.data.user.class
            }))
            dispatch(setUser(res.data.user))

            setSending(false)

        }
        catch (err)
        {
            setSending(false)
            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    useEffect(() =>
    {
        getProfile()
    }, [])

    useEffect(() =>
    {
        if (props.dirty || image)
        {
            return setDirty(true)
        }
    }, [image, props.dirty])

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Card style={{ height: 300 }} profile>
                        <CardAvatar profile>
                            <img src={mediaUrl + data.user.profilePicture} />
                        </CardAvatar>
                        <CardBody profile>
                            <h6 className={classes.cardCategory}>{data.user.displayName}</h6>
                            <h4 className={classes.cardTitle}>@{data.user.username}</h4>
                            <h4 className={classes.cardTitle}>{data.user.class}</h4>
                        </CardBody>
                    </Card>
                </GridItem>
                <UploadMedia disableControls={disableControls} image={image} setImage={setImage} />
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Profili Düzenle</h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={4} style={{ justifyContent: 'unset' }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field name="displayName" component={RenderTextField} label="Görünen Ad" fullWidth InputProps={{ readOnly: disableControls }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field name="username" component={RenderTextField} label="Kullanıcı Adı" fullWidth InputProps={{ readOnly: disableControls }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field name="email" component={RenderTextField} label="E-posta" fullWidth InputProps={{ readOnly: disableControls }} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field name="class" component={RenderTextField} label="Kullanıcı Türü" fullWidth InputProps={{ readOnly: true }} />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter style={{ justifyContent: 'flex-end' }}>
                            <Button disabled={disableControls || !dirty} onClick={() => updateProfile()} color="primary">Profili Güncelle</Button>
                        </CardFooter>
                    </Card>
                </GridItem>

            </GridContainer>

        </div>
    );
}

const UploadMedia = ({ image, setImage, disableControls }) =>
{
    const classes = useStyles();
    const [uploading, setUploading] = useState(false)

    const aws = useSelector(state => state.auth.aws)
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });

    const upload = async (file) =>
    {
        setUploading(true)
        try
        {
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: 'images/' + file.name
            };

            const command = new PutObjectCommand(params)

            const res = await client.send(command)

            setImage('images/' + file.name)
        }
        catch (err)
        {
            console.log(err)
        }
        finally
        {
            setUploading(false)
        }
    }

    const onDrop = useCallback(acceptedFiles => upload(acceptedFiles[0]), [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/png,image/jpeg',
        onDrop,
        maxFiles: 1
    })

    return (
        <Grid item xs={12} sm={12} md={4}>
            <Card style={{ height: 300 }} profile>
                <CardHeader color="primary">
                    <h4 style={{ textAlign: 'start' }} className={classes.cardTitleWhite}>Profil Fotoğrafı Yükle</h4>
                </CardHeader>
                <CardBody style={{ marginTop: 0 }} profile>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} disabled={disableControls} />
                        {
                            image ?
                                (<Box style={{ height: 220 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                    <img src={mediaUrl + image} style={{ height: '200px', width: '100%' }} />
                                </Box>)
                                :
                                (<Box style={{ height: 220 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                    {
                                        uploading ? <CircularProgress /> : (
                                            <>
                                                <ImageIcon />
                                                <p>Resmi bu alana bırakın yada bilgisayarınızdan seçin</p>
                                            </>
                                        )
                                    }

                                </Box>
                                )
                        }
                    </div>
                </CardBody>
            </Card>
        </Grid>

    )
}


export default reduxForm({
    form: 'userDetail'
})(UserDetail)