import React, { useEffect,useState } from "react";
// @material-ui/core components
import { makeStyles, Box, Button } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import RecordsTable from "components/Table/RecordsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import WebService from 'services/WebService'

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

export default function Records()
{
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState({
        edges: []
    })
    const [search,setSearch] = useState('')

    const getData = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: productsQuery,
                variables: {
                    first:20,
                   query: `product_type:vinly ${search ? 'title:' + search : ''}`,
                }
            })
          
            if(res.data.products) {
                setData(res.data.products)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const fetchNextPage = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: productsQuery,
                variables: {
                    after:data.edges[data.edges.length -1].cursor,
                   query: `product_type:vinly ${search ? 'title:' + search : ''}`,
                   first:20
                }
            })
          
            if(res.data.products) {
                setData(res.data.products)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const fetchPrevPage = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: productsQuery,
                variables: {
                    before:data.edges[0].cursor,
                   query: `product_type:vinly ${search ? 'title:' + search : ''}`,
                   last:20
                }
            })
          
            if(res.data.products) {
                setData(res.data.products)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    useEffect(() =>
    {
        getData()
    }, [])

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} >
                <Box display='flex' justifyContent='flex-end' mb={4}>
                    <Button onClick={() => history.push('/vinyl/new')} variant='contained'>Yeni Plak</Button>
                </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Plaklar</h4>
                    </CardHeader>
                    <CardBody>
                        <RecordsTable
                            tableHeaderColor="primary"
                            tableHead={["", "Başlık", "Fiyat Aralığı", "Oluşturma Tarihi"]}
                            tableData={data.edges}
                        />
                        <Box mt={4} display='flex' justifyContent='center' >
                            <Button disabled={!data.pageInfo?.hasPreviousPage} onClick={() => fetchPrevPage()} variant='contained'>Önceki Sayfa</Button>
                            <Button disabled={!data.pageInfo?.hasNextPage} style={{ marginLeft: 40 }} onClick={() => fetchNextPage()} variant='contained'>Sonraki Sayfa</Button>
                        </Box>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const productsQuery = `
query products($after:String,$before:String,$query:String,$first:Int,$last:Int) {
    products(first:$first,last:$last,after:$after,before:$before,query:$query){
      edges {
        cursor
        node {
            legacyResourceId
          title
          featuredImage {
            transformedSrc(maxWidth:256,maxHeight:256)
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`