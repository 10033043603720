import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, Dialog, Paper, Grid, Typography, TextField } from "@material-ui/core";
// core components
import BlogTranslationTable from "components/Table/BlogTranslationTable.js";
import WebService from 'services/WebService'
import { useAlert } from 'react-alert'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Pagination from '@material-ui/lab/Pagination';
import { Editor } from '@tinymce/tinymce-react';
import { mediaUrl } from 'utils/urls'
import store from '../../store'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';



const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
        padding: 10
    },
    modal: {
        backgroundColor:'transparent'
    },
    modalPaper: {
        maxWidth: 1080,
        width: '100%',
        backgroundColor:'white'
    },
    dialogRoot:{
        backgroundColor:'transparent',
        alignItems:'center',
        paddingTop:50
    }
}));

export default function BlogTab()
{
    const [data, setData] = useState({
        totalPages: 0,
        activities: []
    })
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [values,setValues] = useState({
        title:'',
        description:'',
        place:'',
        date:''
    })
    const [modal,setModal] = useState(false)
    const styles = useStyles();
    const alert = useAlert()

    const getAll = async () =>
    {
        {
            try
            {
                const res = await WebService.get('/localization/activity', {
                    params: {
                        page: page, search: search
                    }
                })

                setData(res.data)
            }
            catch (err)
            {
                if (err.response)
                {
                    return console.log(err.response)
                }

                console.log(err)
            }
        }
    }

    const handleClick = (id) =>
    {
        const modalData = data.activities.find(x => x._id == id)
        setModal(modalData)
        setValues({
            title:modalData.locales?.find(x => x.locale == 'en')?.title ? modalData.locales?.find(x => x.locale == 'en')?.title :'',
            description:modalData.locales?.find(x => x.locale == 'en')?.description ? modalData.locales?.find(x => x.locale == 'en')?.description :'',
            place:modalData.locales?.find(x => x.locale == 'en')?.place ? modalData.locales?.find(x => x.locale == 'en')?.place :'',
            date:modalData.locales?.find(x => x.locale == 'en')?.date ? modalData.locales?.find(x => x.locale == 'en')?.date :'',
        })
    }

    const handleClose = () => {
        setModal(false)
        setValues({
            title:'',
            description:'',
            place:'',
            date:''
        })
    }

    const saveTranslation = async() => {
        try {
            const locale = {
                locale:'en',
                title:values.title,
                description:values.description,
                place:values.place,
                date:values.date

            }

            const res = await WebService.post(`localization/activity/${modal._id}`,locale)

            if(res.data.activity){
                let newActivity = data.activities

                newActivity[newActivity.indexOf(newActivity.find(x => x._id == modal._id))] = res.data.activity

                alert.success('Çeviri kaydedildi')
                handleClose()
            }
        }
        catch(err){
            alert.error('bilinmeyen hata')
            console.log(err)
        }
    }

    useEffect(() =>
    {
        setPage(1)
        getAll()
    }, [search])

    useEffect(() =>
    {
        getAll()
    }, [page])

    return (
        <>
        <Dialog
                open={modal ? true : false}
                onClose={() => handleClose()}
                className={styles.modal}
                fullScreen
                classes={{paper:styles.dialogRoot}}
            >
                <Box padding={4} className={styles.modalPaper}>
                    {
                        modal && (
                            <Box   >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Başlık</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.title}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Başlık En'
                                                        value={values.title}
                                                        onChange={(e) => setValues({...values,title:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Açıklama</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.description}
                                                        variant='outlined'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Açıklama En'
                                                        value={values.description}
                                                        onChange={(e) => setValues({...values,description:e.target.value})}
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>    
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Yer</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.place}
                                                        variant='outlined'
                                                        fullWidth
                                                        multiline
                                                 
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Yer En'
                                                        value={values.place}
                                                        onChange={(e) => setValues({...values,place:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>   
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Zaman</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.date}
                                                        variant='outlined'
                                                        fullWidth
                                                        multiline
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Açıklama En'
                                                        value={values.date}
                                                        onChange={(e) => setValues({...values,date:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>                                
                                </Grid>
                            </Box>
                        )
                    }
                    <Box mt={6} display='flex' justifyContent='space-between'>
                        <Button onClick={() => handleClose()} variant="contained" color="secondary">İptal</Button>
                        <Button onClick={() => saveTranslation()} variant="contained" color="primary">Kaydet</Button>
                    </Box>
                </Box>
            </Dialog>
            <BlogTranslationTable
                tableHeaderColor="primary"
                tableHead={["Başlık", "Açıklama", "Başlık", "Açıklama"]}
                tableData={data.activities}
                handleClick={handleClick}
            />
            <Box mt={4} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
            </Box>

        </>
    );
}

async function image_upload_handler(blobInfo, success, failure, progress)
{
    const aws = store.getState().auth.aws
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });
    
    try
    {
        const params = {
            ACL: 'public-read',
            Body: blobInfo.blob(),
            Bucket: S3_BUCKET,
            Key: 'images/' + blobInfo.filename()
        };

        const command = new PutObjectCommand(params)

        await client.send(command)

        success(mediaUrl + 'images/' + blobInfo.filename())
    }
    catch (err)
    {
        console.log(err)
        failure('Image upload failed');
    }
};