import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, TextField } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PlaylistTableForRadio from "components/Table/PlaylistTableForRadio";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Pagination from '@material-ui/lab/Pagination';
import { useAlert } from 'react-alert'


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    color: 'white'
  }
};

const useStyles = makeStyles(styles);

export default function Playlists()
{
  const [data, setData] = useState({
    totalPages: 0,
    playlists: []
  })
  const [metadata,setMetadata] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const classes = useStyles();
  const alert = useAlert()

  const getMetadata = async () => {
    try
    {
      const res = await WebService.get('playlist/metadata')

      setMetadata(res.data.metadata)
    }
    catch (err)
    {
      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  const saveMetadata = async () => {
    try
    {
      const res = await WebService.post('playlist/metadata', {
        meta:metadata
      })

      setMetadata(res.data.metadata)
      alert.success('Metadata güncellendi')
    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')
      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  const getAll = async () =>
  {
    {

      try
      {
        const res = await WebService.get('playlist', {
          params: {
            page: page, search: search
          }
        })

        setData(res.data)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  const handleActivate = async (playlistId) =>
  {
    try
    {
      const res = await WebService.post(`playlist/activate`, { playlistId: playlistId })

      alert.success('Çalma listesi etkinleştirildi')
      getAll()
    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  useEffect(() =>
  {
    setPage(1)
    getAll()
  }, [search])

  useEffect(() =>
  {
    getAll()
  }, [page])

  useEffect(() => {
    getMetadata()
  },[])

  return (
    <>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
          <GridItem xs={12} sm={4}>
              <Card>
                <CardHeader color="primary">
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <h4 className={classes.cardTitleWhite}>Metadata</h4>
                  </Box>
                </CardHeader>
                <CardBody>
                <TextField
                    value={metadata}
                    labelText="Metadata"
                    id="meta"
                    onChange= {(e) => setMetadata(e.target.value)}
                    fullWidth
                  />
                </CardBody>
                <CardFooter style={{ justifyContent: 'flex-end' }}>
                  <Button color="primary" onClick={() => saveMetadata()} >Kaydet</Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <Card>
                <CardHeader color="primary">
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <h4 className={classes.cardTitleWhite}>Radyo</h4>
                  </Box>
                </CardHeader>
                <CardBody>
                  <AudioPlayer
                    autoPlay
                    src="http://ec2-52-59-192-112.eu-central-1.compute.amazonaws.com:3010/radio"
                    autoPlay={false}
                    showJumpControls={false}
                    showDownloadProgress={false}
                    showFilledProgress={false}
                    customAdditionalControls={[]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Çalma Listeleri</h4>
                <CustomInput
                  value={search}
                  labelText="Ara"
                  id="ara"
                  inputProps={{
                    onChange: (e) => setSearch(e.target.value),
                    style: { color: 'white' }
                  }}
                  formControlProps={{
                    fullWidth: false,
                    style: { margin: 'unset' }
                  }}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <PlaylistTableForRadio
                tableHeaderColor="primary"
                tableHead={["Ad", "Parça Sayısı", "Durum", "Oluşturma Tarihi", ""]}
                tableData={data.playlists}
                handleActivate={handleActivate}
                active={data.active}
              />
              <Box mt={2} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
              </Box>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
