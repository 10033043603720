import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, Dialog, Paper, Grid, Typography, TextField } from "@material-ui/core";
// core components
import ProductTranslationsTable from "components/Table/ProductTranslationsTable";
import WebService from 'services/WebService'
import { Editor } from '@tinymce/tinymce-react';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { mediaUrl } from 'utils/urls'
import {useAlert} from 'react-alert'
import store from '../../store'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';




const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
        padding: 10
    },
    modal: {
        backgroundColor:'transparent'
    },
    modalPaper: {
        maxWidth: 1080,
        width: '100%',
        backgroundColor:'white'
    },
    dialogRoot:{
        backgroundColor:'transparent',
        alignItems:'center',
        paddingTop:50
    }
}));

const ProductTab = (props) =>
{
    const styles = useStyles()
    const [data, setData] = useState({
        edges: []
    })
    const [modal, setModal] = useState(false)
    const [values,setValues] = useState({
        title:'',
        description:'',
        metaTitle:'',
        metaDescription:''
    })
    const alert = useAlert()

    const getData = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: translatableResourcesQuery,
                variables: {
                    "resourceType": "PRODUCT",
                    "locale": "en",
                    "after": null,
                    "before": null,
                    "first": 10
                }
            })

            if (res.status == 200)
            {
                setData(res.data.translatableResources)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const fetchNextPage = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: translatableResourcesQuery,
                variables: {
                    "resourceType": "PRODUCT",
                    "locale": "en",
                    "after": data.edges[data.edges.length - 1].cursor,
                    "before": null,
                    "first": 10
                }
            })

            if (res.status == 200)
            {
                setData(res.data.translatableResources)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const fetchPrevPage = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: translatableResourcesQuery,
                variables: {
                    "resourceType": "PRODUCT",
                    "locale": "en",
                    "after": null,
                    "before": data.edges[0].cursor,
                    "last": 10
                }
            })

            if (res.status == 200)
            {
                setData(res.data.translatableResources)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const handleClick = (cursor) =>
    {
        const modalData = data.edges.find(x => x.cursor == cursor)
        setModal(modalData)
        setValues({
            title:modalData.node.translations?.find(x => x.key == 'title')?.value ? modalData.node.translations?.find(x => x.key == 'title')?.value :'',
            description:modalData.node.translations?.find(x => x.key == 'title')?.value ? modalData.node.translations?.find(x => x.key == 'body_html')?.value :'',
            metaTitle:modalData.node.translations?.find(x => x.key == 'title')?.value ? modalData.node.translations?.find(x => x.key == 'meta_title')?.value :'',
            metaDescription:modalData.node.translations?.find(x => x.key == 'title')?.value ? modalData.node.translations?.find(x => x.key == 'meta_description')?.value :'',
        })
    }

    const handleClose = () => {
        setModal(false)
        setValues({
            title:'',
            description:'',
            metaTitle:'',
            metaDescription:''
        })
    }

    const saveTranslations = async() => {
        try {
            const resourceId = modal.node.resourceId
            let variables = {
                resourceId: resourceId,
                translations: []
            }

            if(values.title) {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "title",
                        value: values.title,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'title').digest
                    })
            }

            
            if(values.description) {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "body_html",
                        value: values.description,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'body_html').digest
                    })
            }

            
            if(values.metaTitle) {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "meta_title",
                        value: values.metaTitle,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'meta_title').digest
                    })
            }

            
            if(values.metaDescription) {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "meta_description",
                        value: values.metaDescription,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'meta_description').digest
                    })
            }

            const res0 = await WebService.post('graphql', {
                query: translationsRegisterQuery,
                variables: variables
            })

            if (res0.status == 200)
            {
                const res1 = await WebService.post('graphql',{
                    query:translatableResourceQuery,
                    variables:{
                        resourceId:resourceId,
                        locale:'en'
                    }
                })

                let newData = data

                newData.edges.find(x => x.node.resourceId == resourceId).node.translatableContent = res1.data.translatableResource.translatableContent
                newData.edges.find(x => x.node.resourceId == resourceId).node.translations = res1.data.translatableResource.translations

                setData(newData)

                alert.success('Çeviri kaydedildi.')
                handleClose()
            }
        }
        catch(err)
        {
            alert.error('Bilinmeyen hata')
            console.log(err)
        }
    }   

    useEffect(() =>
    {
        getData()
    }, [])

    return (
        <>
            <Dialog
                open={modal ? true : false}
                onClose={() => handleClose()}
                className={styles.modal}
                fullScreen
                classes={{paper:styles.dialogRoot}}
            >
                <Box padding={4} className={styles.modalPaper}>
                    {
                        modal && (
                            <Box   >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Başlık</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'title').value}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Başlık En'
                                                        value={values.title}
                                                        onChange={(e) => setValues({...values,title:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Açıklama</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'body_html').value}
                                                        variant='outlined'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Editor
                                                    value={values.description}
                                                    onEditorChange={(value, editor) => setValues({...values,description:value})}
                                                       
                                                        init={{
                                                            height: 200,
                                                            menubar: true,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            images_upload_handler: image_upload_handler,
                                                            toolbar: 'undo redo | formatselect | ' +
                                                                'bold italic backcolor | alignleft aligncenter ' +
                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                'removeformat | help'
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Seo Başlık</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'meta_title').value}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Seo Başlık En'
                                                        value={values.metaTitle}
                                                        onChange={(e) => setValues({...values,metaTitle:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Seo Açıklama</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'meta_description').value}
                                                        variant='outlined'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        placeholder='Seo Açıklama En'
                                                        value={values.metaDescription}
                                                        onChange={(e) => setValues({...values,metaDescription:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    }
                    <Box mt={6} display='flex' justifyContent='space-between'>
                        <Button onClick={() => handleClose()} variant="contained" color="secondary">İptal</Button>
                        <Button onClick={() => saveTranslations()} variant="contained" color="primary">Kaydet</Button>
                    </Box>
                </Box>
            </Dialog>
            <ProductTranslationsTable
                tableHeaderColor="primary"
                tableHead={["Başlık", "Açıklama", "Başlık", "Açıklama"]}
                tableData={data.edges}
                handleClick={handleClick}
            />
            <Box mt={4} display='flex' justifyContent='center' >
                <Button disabled={!data.pageInfo?.hasPreviousPage} onClick={() => fetchPrevPage()} variant='contained'>Önceki Sayfa</Button>
                <Button disabled={!data.pageInfo?.hasNextPage} style={{ marginLeft: 40 }} onClick={() => fetchNextPage()} variant='contained'>Sonraki Sayfa</Button>
            </Box>
        </>
    )
}

export default ProductTab

async function image_upload_handler(blobInfo, success, failure, progress)
{
    
    const aws = store.getState().auth.aws
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });
    
    try
    {
        const params = {
            ACL: 'public-read',
            Body: blobInfo.blob(),
            Bucket: S3_BUCKET,
            Key: 'images/' + blobInfo.filename()
        };

        const command = new PutObjectCommand(params)

        await client.send(command)

        success(mediaUrl + 'images/' + blobInfo.filename())
    }
    catch (err)
    {
        console.log(err)
        failure('Image upload failed');
    }
};

const translatableResourcesQuery = `query translatableResources($first:Int,$last:Int,$resourceType:TranslatableResourceType!,$locale:String!,$after:String,$before:String) {
    translatableResources(first:$first,last:$last,resourceType:$resourceType,after:$after,before:$before){
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          resourceId
          translatableContent {
            key
            locale
            digest
            value
          }
          translations(locale:$locale){
            key
            locale
            outdated
            value
          }
        }
      }
    }
  }`

  const translatableResourceQuery = `
  query translatableResource($resourceId: ID!,$locale:String!){
    translatableResource(resourceId:$resourceId)
    {
          resourceId
          translatableContent {
            key
            digest
            value
            locale
          }
          translations(locale:$locale) {
            value
            key
            locale
            outdated
          }
          
        }
  }`

  const translationsRegisterQuery = `
  mutation translationsRegister($resourceId: ID!, $translations: [TranslationInput!]!) {
    translationsRegister(resourceId: $resourceId, translations: $translations) {
      translations {
        key
        locale
        outdated
        value
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  `