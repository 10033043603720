import React from "react";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import WebService from 'services/WebService'
import { DateTime } from "luxon";

const useStyles = makeStyles(styles);

export default function Notes({data,getAll}) {
  const classes = useStyles();
  const tableCellClasses = classnames(classes.tableCell);

  const handleToggle =async (id,status) => {
    try {
      await WebService.post(`note/${id}`,{status:status})
      getAll()
    }
    catch(err)
    {
      console.log(err)
    }
  };

  const deleteNote =async (id) => {
    try {
      await WebService.delete(`note/${id}`)
      getAll()
    }
    catch(err)
    {
      console.log(err)
    }
  }

  return (
    <Table className={classes.table}>
      <TableBody>
        {data.map((value,i) => (
          <TableRow key={i} className={classes.tableRow}>
            <TableCell className={tableCellClasses}>
              <Checkbox
                checked={value.status}
                tabIndex={-1}
                onClick={() => handleToggle(value._id,!value.status)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root
                }}
              />
            </TableCell>
            <TableCell  className={tableCellClasses}>{value.content}</TableCell>
            <TableCell className={tableCellClasses}>{DateTime.fromISO(value.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }</TableCell>
            <TableCell style={{padding:'16px !important'}} className={classes.tableActions}>
             
                <IconButton
                  aria-label="Close"
                  className={classes.tableActionButton}
                  onClick={() => deleteNote(value._id)}
                >
                  <Close
                    className={
                      classes.tableActionButtonIcon + " " + classes.close
                    }
                  />
                </IconButton>
            
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
