import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import BlogTable from "components/Table/BlogTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Pagination from '@material-ui/lab/Pagination';


const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    color: 'white'
  }
};

const useStyles = makeStyles(styles);

export default function Blogs()
{
  const [data, setData] = useState({
    totalPages:0,
    blogs:[]
  })
  const [page,setPage] = useState(1)
  const [search,setSearch] = useState('')
  const classes = useStyles();
  const alert = useAlert()
  const history= useHistory()

  const getAll = async () =>
  {
    {

      try
      {
        const res = await WebService.get('blog',{
          params:{
            page:page,search:search
          }
        })

        setData(res.data)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  const handleDelete = async(id) => {
    try {
      const res = await WebService.delete(`blog/${id}`)

      alert.success('Blog silindi')
      getAll()
    }
    catch(err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  useEffect(() =>
  {
    setPage(1)
    getAll()
  }, [search])

  useEffect(() =>
  {
    getAll()
  }, [page])

  return (
    <>

      <GridContainer>
      <GridItem xs={12} sm={12} md={12} >
                <Box display='flex' justifyContent='flex-end' mb={4}>
                    <Button onClick={() => history.push('/blog/new')} variant='contained'>Yeni Blog</Button>
                </Box>
            </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Bloglar</h4>
                <CustomInput
                    value={search}
                    labelText="Ara"
                    id="ara"
                    inputProps={{
                      onChange: (e) => setSearch(e.target.value),
                      style:{color:'white'}
                    }}
                    formControlProps={{
                      fullWidth: false,
                      style:{margin:'unset'}
                    }}
                  />
              </Box>
            </CardHeader>
            <CardBody>
              <BlogTable
                tableHeaderColor="primary"
                tableHead={["", "Başlık", "Açıklama", "Yükleme Tarihi", ""]}
                tableData={data.blogs}
                handleDelete={handleDelete}
              />
              <Box mt={2} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e,value) => setPage(value)} />
              </Box>
             
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}