let publicUrl, apiUrl,mediaUrl;

if (process.env.NODE_ENV === 'production') {
    mediaUrl = 'https://vamanos.s3.eu-central-1.amazonaws.com/'
    apiUrl = 'https://api.vamonos.ist/v1'
} else {
    mediaUrl = 'https://vamanos.s3.eu-central-1.amazonaws.com/'
    apiUrl = 'http://localhost:3000/v1'
}



export {
    apiUrl,
    mediaUrl
}