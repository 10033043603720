import React, { useState, useCallback } from 'react'
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, Grid, Typography } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import { useAlert } from 'react-alert'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { mediaUrl } from 'utils/urls'
import {useSelector} from 'react-redux'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';



const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        color: 'white'
    }
};

const useStyles = makeStyles(styles);

const Contracts = (props) =>
{
    const classes = useStyles();
    const alert = useAlert()

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <h4 className={classes.cardTitleWhite}>Kullanım Koşulları</h4>
                        </Box>
                    </CardHeader>
                    <CardBody>
                        <Box p={2}>
                            <Typography style={{marginTop:10,marginBottom:10}} variant='h5' component='p'>TR</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <UploadFile fileName='eula.pdf' locale='tr' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' component='a' href={`${mediaUrl}documents/tr/eula.pdf`} target='_blank'>İndir</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={2}>
                            <Typography style={{marginTop:10,marginBottom:10}} variant='h5' component='p'>EN</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <UploadFile fileName='eula.pdf' locale='en' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' component='a' href={`${mediaUrl}documents/en/eula.pdf`} target='_blank'>İndir</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <h4 className={classes.cardTitleWhite}>Gizlilik Sözleşmesi</h4>
                        </Box>
                    </CardHeader>
                    <CardBody>
                        <Box p={2}>
                            <Typography style={{marginTop:10,marginBottom:10}} variant='h5' component='p'>TR</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <UploadFile fileName='privacy_policy.pdf' locale='tr' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' component='a' href={`${mediaUrl}documents/tr/privacy_policy.pdf`} target='_blank'>İndir</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={2}>
                            <Typography style={{marginTop:10,marginBottom:10}} variant='h5' component='p'>EN</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <UploadFile fileName='privacy_policy.pdf' locale='en' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' component='a' href={`${mediaUrl}documents/en/privacy_policy.pdf`} target='_blank'>İndir</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <h4 className={classes.cardTitleWhite}>Mesafeli Satış Sözleşmesi</h4>
                        </Box>
                    </CardHeader>
                    <CardBody>
                        <Box p={2}>
                            <Typography style={{marginTop:10,marginBottom:10}} variant='h5' component='p'>TR</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <UploadFile fileName='distance_sales_agreement.pdf' locale='tr' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' component='a' href={`${mediaUrl}documents/tr/distance_sales_agreement.pdf`} target='_blank'>İndir</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={2}>
                            <Typography style={{marginTop:10,marginBottom:10}} variant='h5' component='p'>EN</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8}>
                                    <UploadFile fileName='distance_sales_agreement.pdf' locale='en' />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' component='a' href={`${mediaUrl}documents/en/distance_sales_agreement.pdf`} target='_blank'>İndir</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default Contracts


function UploadFile({ fileName, locale })
{
    const [uploading, setUploading] = useState(false)
    const alert = useAlert()
    const classes = useStyles();

    const client = new S3Client({
        region: REGION,
        credentials: {
            accessKeyId: 'AKIA6C4U2U2SJ6C3LK6O',
            secretAccessKey: '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'
        }
    });

    const onDrop = useCallback(acceptedFiles =>
    {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () =>
        {
            // Do whatever you want with the file contents
            const binaryStr = reader.result
            upload(binaryStr)
        }
        reader.readAsArrayBuffer(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'application/pdf',
        onDrop,
        maxFiles: 1
    })

    const upload = async (file) =>
    {
        setUploading(true)
        try
        {

            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: 'documents/' + locale + '/' + fileName
            };

            const command = new PutObjectCommand(params)

            const res0 = await client.send(command)

            alert.success('Döküman Yüklendi')

        }
        catch (err)
        {
            alert.error('Bilinmeyen hata')

            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
        finally
        {
            setUploading(false)
        }
    }

    return (
        <div style={{ border: '1px solid gray', borderRadius: 5 }}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    uploading ?
                        (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <CircularProgress />
                        </Box>)
                        :
                        (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <PublishIcon />
                            <p>Dosyayı bu alana bırakın yada bilgisayarınızdan seçin</p>
                        </Box>
                        )
                }
            </div>
        </div>
    );
}
