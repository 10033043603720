import store  from '../store'
import { reset } from '../slices/authSlicer'

export async function logout()
{
    try
    {
        localStorage.removeItem('token')
        store.dispatch(reset())
    }
    catch (err)
    {
        if (err.response)
        {
            return console.log(err.response)
        }

        console.log(err)
    }

}