import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, IconButton, Menu, MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import { mediaUrl } from 'utils/urls'


const useStyles = makeStyles(styles);

export default function ProductTranslationsTable(props)
{

  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              <TableCell colSpan={2} align="center">
                TR
              </TableCell>
              <TableCell colSpan={2} align="center">
                EN
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key} handleClick={props.handleClick} />
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data,handleClick })
{
  const classes = useStyles();

  return (
    <TableRow onClick={() => handleClick(data.cursor)} className={classes.tableBodyRow} style={{cursor:'pointer'}} >
      <TableCell className={classes.tableCell} >
        {data.node.translatableContent.find(x => x.key == 'title')?.value}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data.node.translatableContent.find(x => x.key == 'body_html')?.value.substring(0,160)}
      </TableCell>
      <TableCell className={classes.tableCell} >
      {data.node.translations.find(x => x.key == 'title')?.value}
      </TableCell>
      <TableCell className={classes.tableCell} >
         {data.node.translations.find(x => x.key == 'body_html')?.value.substring(0,160)}
      </TableCell>
    </TableRow>
  )
}