import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PlaylistTable from "components/Table/PlaylistTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'

import Pagination from '@material-ui/lab/Pagination';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    color: 'white'
  }
};

const useStyles = makeStyles(styles);

export default function Playlists()
{
  const [data, setData] = useState({
    totalPages:0,
    playlists:[]
  })
  const [page,setPage] = useState(1)
  const [search,setSearch] = useState('')
  const classes = useStyles();
  const alert = useAlert()

  const getAll = async () =>
  {
    {

      try
      {
        const res = await WebService.get('playlist',{
          params:{
            page:page,search:search
          }
        })

        setData(res.data)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  const handleDelete = async(id) => {
    try {
      const res = await WebService.delete(`playlist/${id}`)

      alert.success('Çalma listesi silindi')
      getAll()
    }
    catch(err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  useEffect(() =>
  {
    setPage(1)
    getAll()
  }, [search])

  useEffect(() =>
  {
    getAll()
  }, [page])

  return (
    <>
     <NewPlaylist getAll={getAll} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Çalma Listeleri</h4>
                <CustomInput
                    value={search}
                    labelText="Ara"
                    id="ara"
                    inputProps={{
                      onChange: (e) => setSearch(e.target.value),
                      style:{color:'white'}
                    }}
                    formControlProps={{
                      fullWidth: false,
                      style:{margin:'unset'}
                    }}
                  />
              </Box>
            </CardHeader>
            <CardBody>
              <PlaylistTable
                tableHeaderColor="primary"
                tableHead={["Ad", "Parça Sayısı","Durum","Oluşturma Tarihi", ""]}
                tableData={data.playlists}
                handleDelete={handleDelete}
                active={data.active}
              />
              <Box mt={2} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e,value) => setPage(value)} />
              </Box>
             
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

function NewPlaylist({ getAll })
{
  const [values, setValues] = useState({ name: '' })
  const [sending,setSending] = useState(false)
  const alert = useAlert()
  const classes = useStyles();

  const createPlaylist = async () =>
  {
    if (!values.name) return
    setSending(true)
    try
    {

      const res = await WebService.post('playlist', {
        name:values.name
      })

      console.log(res)
      alert.success('Şarkı yüklendi')
      getAll()
      setValues({ name: null})

    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
    finally
    {
        setSending(false)
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Yeni Çalma Listesi</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    value={values.name}
                    labelText="Çalma Listesi Adı"
                    id="name"
                    inputProps={{
                      onChange: (e) => setValues({ ...values, name: e.target.value })
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: 'flex-end' }}>
              <Button disabled={sending} color="primary" onClick={() => createPlaylist()} >Oluştur</Button>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
