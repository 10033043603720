import React from 'react'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const AlertTemplate = ({ message, options, style, close }) =>
{
    return (
        <div style={{ ...style }}>
            {
                options.type === 'info' &&
                <SnackbarContent
                    message={message}
                    closeNotification={close}
                    color="info"
                />
            }
            {
                options.type === 'success' &&
                <SnackbarContent
                    message={message}
                    closeNotification={close}
                    color="success"
                />
            }
            {
                options.type === 'error' &&
                <SnackbarContent
                    message={message}
                    closeNotification={close}
                    color="danger"
                />
            }
        </div>
    )
}

export default AlertTemplate