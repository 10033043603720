import React from 'react'
import {TextField} from '@material-ui/core'
import { RestaurantRounded } from '@material-ui/icons'

export const RenderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...rest
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...rest}
    />
  )
