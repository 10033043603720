import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, IconButton, Menu, MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Chip from '@material-ui/core/Chip';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { DateTime } from "luxon";

const useStyles = makeStyles(styles);

export default function PlaylistTableForRadio(props)
{
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key} handleActivate={props.handleActivate} active={props.active} />
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data,handleActivate,active })
{
  const classes = useStyles();

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell} >
        {data.name}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data.songs.length}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data._id == active ? <Chip label="Aktif"  color="primary" /> : false}
      </TableCell>
      <TableCell className={classes.tableCell} >
      {DateTime.fromISO(data.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }
      </TableCell>
      <TableCell className={classes.tableCell} >
        <IconButton disabled={data._id == active ? true : false} onClick={()=> handleActivate(data._id)} size="small" >
          <PlayCircleOutlineIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}