import React, {useState, useCallback, useEffect,useRef  } from 'react'
import { Box, Paper, makeStyles, CircularProgress, Button,Modal,Backdrop,Fade } from '@material-ui/core'
import { reduxForm, Field,formValueSelector,initialize  } from 'redux-form'
import { RenderTextField, RenderEditor, RenderCreatableSelect } from 'components/FormFields/'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useDropzone } from 'react-dropzone'
import ImageIcon from '@material-ui/icons/Image';
import { mediaUrl } from 'utils/urls'
import {useSelector} from 'react-redux'
import {required} from 'utils/validation'
import WebService from 'services/WebService'
import {useHistory,useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useAlert} from 'react-alert'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';



const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};
const useStyles = makeStyles(styles);

const EditBlog = (props) =>
{
    const classes = useStyles();
    const [image, setImage] = useState('')
    const state = useSelector(state => state)
    const [sending,setSending] = useState(false)
    const history = useHistory()
    const {id} = useParams()
    const dispatch = useDispatch()
    const alert = useAlert()

    const getData = async() => {
        try {
            const res = await WebService.get(`blog/edit/${id}`)

            dispatch(initialize(
                'editBlog',{
                    title:res.data.blog.title,
                    desc:res.data.blog.shortDesc,
                    body:res.data.blog.body,
                    seoTitle:res.data.blog.seo.title,
                    seoDesc:res.data.blog.seo.description
                },
                false
            ))

            setImage(res.data.blog.media)
        }
        catch(err)
        {
            console.log(err)
        }
    }

    const sendRequest = async() => {
        if(!props.valid || !image) return
        setSending(true)
        try {
            const selector = formValueSelector('editBlog')
            const values = selector(state, 'title','desc','body','seoTitle','seoDesc')
        
            const data = {
                title:values.title,
                shortDesc:values.desc,
                description:values.body,
                media:image,
                seoTitle:values.seoTitle,
                seoDescription:values.seoDesc
            }

            const res = await WebService.put(`blog/${id}`,data)

            setSending(false)
            alert.success('Blog güncellendi')
            // history.push('/blog')
        }
        catch(err)
        {
            alert.error('Bilinmeyen hata')
            setSending(false)
            if(err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <GridContainer>
            <GridItem xs={12} sm={9} >
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Yeni Blog</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer spacing={4}>
                            <GridItem xs={12} sm={12} >
                                <Paper style={{ padding: 15, paddingTop: 5, }}>
                                    <h4>Genel</h4>
                                    <Box>
                                        <Field name="title" component={RenderTextField} label="Blog Başlığı" variant="outlined" fullWidth validate={[required]} />
                                    </Box>
                                    <Box mt={4}>
                                        <Field name="desc" component={RenderTextField} label="Blog Açıklaması" variant="outlined" multiline rows={4} fullWidth validate={[required]} />
                                    </Box>
                                    <Box mt={4}>
                                        <Field name="body" component={RenderEditor} validate={[required]} />
                                    </Box>
                                </Paper>
                                <Paper style={{ padding: 15, paddingTop: 5, marginTop: 20 }}>
                                    <h4>Blog Resmi</h4>
                                    <UploadMedia image={image} setImage={setImage} />
                                </Paper>
                                <Paper style={{ padding: 15, paddingTop: 5, marginTop: 20 }}>
                                    <h4>Seo</h4>
                                    <Box display='flex' justifyContent='space-between'>
                                        <Field name="seoTitle" component={RenderTextField} fullWidth label="Başlık" variant="outlined"  validate={[required]}/>
                                    </Box>
                                    <Box display='flex' justifyContent='space-between' mt={4}>
                                        <Field name="seoDesc" component={RenderTextField} fullWidth multiline rows={3} label="Açıklama" variant="outlined" validate={[required]} />
                                    </Box>
                                </Paper>
                                <Box mt={6} display='flex' justifyContent='flex-end'>
                                    <Button disabled={!props.valid || sending || !props.dirty} variant='contained' onClick={() => sendRequest()}>Kaydet</Button>
                                </Box>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

const useUploadMediaStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    cropArea: {
        width: 'auto',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 900
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const UploadMedia = ({ image, setImage }) =>
{
    const styles = useUploadMediaStyles()
    const [uploading, setUploading] = useState(false)
    const [openCrop, setOpenCrop] = useState(false)

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 80, aspect: 16 / 9 });

    const aws = useSelector(state => state.auth.aws)
    const client = new S3Client({
      region: REGION,
      credentials: {
        accessKeyId: aws.accessKeyId,
        secretAccessKey: aws.secretAccessKey
      }
    });

    const onLoad = useCallback(img =>
    {
        imgRef.current = img;
    }, []);

    const upload = async (file) =>
    {
        setUploading(true)
        try
        {
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: 'images/' + file.name
            };

            const command = new PutObjectCommand(params)

            const res = await client.send(command)

            setImage('images/' + file.name)
        }
        catch (err)
        {
            console.log(err)
        }
        finally
        {
            setUploading(false)
        }
    }

    const onDrop = useCallback((acceptedFiles) =>
    {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () =>
        {
            setUpImg({ name: acceptedFiles[0].name, file: reader.result })
            setOpenCrop(true)
        }

        reader.readAsDataURL(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/png,image/jpeg',
        onDrop,
        maxFiles: 1
    })

    const endCrop = () =>
    {
        const image = new Image();
        image.src = upImg.file

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / imgRef.current.width;
        const scaleY = image.naturalHeight / imgRef.current.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext("2d");

        const sx = crop.x * scaleX
        const sy = crop.y * scaleY
        const sWidth = crop.width * scaleX
        const sHeight = crop.height * scaleY
        const width = crop.width * scaleX
        const height = crop.height * scaleY

        ctx.drawImage(
            image,
            sx,
            sy,
            sWidth,
            sHeight,
            0,
            0,
            width,
            height
        )

        const reader = new FileReader()
        canvas.toBlob(blob =>
        {
            reader.readAsDataURL(blob)

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () =>
            {
                setOpenCrop(false)
                upload(dataURLtoFile(reader.result, `${Date.now()}.jpg`))
            }
        })
    }

    return (
        <>
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                image ?
                    (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <img src={mediaUrl + image} style={{height:'100%'}} />
                    </Box>)
                    :
                    (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        {
                            uploading ? <CircularProgress /> : (
                                <>
                                    <ImageIcon />
                                    <p>Resmi bu alana bırakın yada bilgisayarınızdan seçin</p>
                                </>
                            )
                        }

                    </Box>
                    )
            }
        </div>
        <Modal
                aria-labelledby='Media Zone'
                aria-describedby='Media Crop'
                open={openCrop}
                onClose={() => setOpenCrop(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={styles.modal}
            >
                <Fade in={openCrop}>
                    <Paper className={styles.paper}>
                        <Box p={2}>
                            <ReactCrop
                                src={upImg?.file}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={c => setCrop(c)}
                                className={styles.cropArea}
                                imageStyle={{ maxHeight: '85vh' }}
                            />
                        </Box>
                        <Box p={2} pt={0} display='flex' justifyContent='center'>
                            <Button variant='contained' onClick={endCrop} >Tamam</Button>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
            </>
    )
}

const dataURLtoFile = (dataurl, filename) =>
{
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--)
    {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage
}

export default reduxForm({
    form: 'editBlog'
})(EditBlog)