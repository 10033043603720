import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Box, Paper, makeStyles, FormControl, Switch, FormControlLabel, TextField, Fade } from '@material-ui/core'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import WebService from 'services/WebService'
import { useParams } from 'react-router-dom';


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};
const useStyles = makeStyles(styles);

const ContactDetail = (props) =>
{
    const classes = useStyles();
    const [data, setData] = useState()
    const { id } = useParams()

    const getData = async () =>
    {
        try
        {
            const res = await WebService.get(`contact/detail/${id}`)
            setData(res.data.contact)

        } catch (err)
        {
            console.log(err)
        }
    }

    const toggleContact = async () =>
    {
        try
        {
            const res = await WebService.post(`contact/toggle/${id}`,{
                status:!data.status
            })
           
            getData()

        } catch (err)
        {
            console.log(err)
        }
    }

    useEffect(() =>
    {
        getData()
    }, [])

    return (
        <GridContainer>
            <GridItem xs={12} sm={9} >
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>İletişim İsteği</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer spacing={4}>
                            <GridItem xs={12} sm={12} >
                                {
                                    data ? (
                                        <Paper style={{ padding: 15, paddingTop: 5, }}>
                                            <Box mt={4}>
                                                <TextField label="Ad Soyad" variant="outlined" fullWidth value={data.fullName} />
                                            </Box>
                                            <Box mt={4}>
                                                <TextField label="E-Posta" variant="outlined" fullWidth value={data.email} />
                                            </Box>
                                            <Box mt={4}>
                                                <TextField label="Telefon" variant="outlined" fullWidth disabled={data.phone ? false : true} value={data.phone} />
                                            </Box>
                                            <Box mt={4}>
                                                <TextField label="Mesaj" variant="outlined" multiline rows={4} fullWidth value={data.message} />
                                            </Box>
                                            <Box mt={8}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={data.status}
                                                            onChange={() => toggleContact()}
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={data.status ? 'Cevaplandı' : 'Beklemede'}
                                                />
                                            </Box>
                                        </Paper>
                                    ) : <></>
                                }

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default ContactDetail