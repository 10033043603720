import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, Typography, TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ParticipantTable from "components/Table/ParticipantTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory, useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Pagination from '@material-ui/lab/Pagination';
import { mediaUrl } from "utils/urls";
import { DateTime } from "luxon";

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        color: 'white'
    }
};

const useStyles = makeStyles(styles);

export default function Activities()
{
    const [data, setData] = useState({
        totalPages: 0,
        participants: []
    })
    const [activity,setActivity] = useState({})
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const classes = useStyles();
    const alert = useAlert()
    const history = useHistory()

    const getData = async () =>
    {
        try
        {
            const res = await WebService.get(`activity/admin/${id}`)

            setActivity(res.data.activity)
        }
        catch (err)
        {
            if (err.response)
            {
                return console.log(err.response)
            }

            console.log(err)
        }
    }

    const getAll = async () =>
    {
        {

            try
            {
                const res = await WebService.get(`activity/admin/participants/${id}`, {
                    params: {
                        page: page
                    }
                })

                setData(res.data)
            }
            catch (err)
            {
                if (err.response)
                {
                    return console.log(err.response)
                }

                console.log(err)
            }
        }
    }

    useEffect(() => {
        getData()
    },[])

    useEffect(() =>
    {
        getAll()
    }, [page])

    return (
        <>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <Box display='flex' justifyContent='flex-end' mb={4}>
                        <Button onClick={() => history.push(`/activity/edit/${id}`)} variant='contained'>Düzenle</Button>
                    </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>Detay</h4>
                            </Box>
                        </CardHeader>
                        <CardBody>
                        <Box mt={2}>
                            <Typography variant='h5'>Başlık:</Typography>
                            <Typography variant='body1'>{activity.title}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Açıklama:</Typography>
                            <Typography variant='body1'>{activity.description}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Yer:</Typography>
                            <Typography variant='body1'>{activity.place}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Tarih:</Typography>
                            <Typography variant='body1'>{activity.date}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Varyant:</Typography>
                            <Typography variant='body1'>{activity.variant == 'black' ? 'siyah' : 'beyaz'}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Arkaplan Rengi:</Typography>
                            <Typography variant='body1'>{activity.backgroundColor}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Oluşturma Tarihi:</Typography>
                            <Typography variant='body1'>{DateTime.fromISO(activity.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }</Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant='h5'>Resim:</Typography>
                            <img style={{width:300}} src={mediaUrl+activity.media}/>
                        </Box>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>Katılımcılar</h4>
                            </Box>
                        </CardHeader>
                        <CardBody>
                            <ParticipantTable
                                tableHeaderColor="primary"
                                tableHead={["E-posta", "Oluşturma Tarihi"]}
                                tableData={data.participants}
                            />
                            <Box mt={2} display='flex' justifyContent='center'>
                                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
                            </Box>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}