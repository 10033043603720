import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, CircularProgress, LinearProgress ,Typography } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MusicTable from "components/Table/MusicTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import CardFooter from "components/Card/CardFooter.js";
import WebService from 'services/WebService'
import CustomInput from "components/CustomInput/CustomInput.js";
import { useAlert } from 'react-alert'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Pagination from '@material-ui/lab/Pagination';
import MP3Tag from 'mp3tag.js'
import { useSelector } from 'react-redux'

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    color: 'white'
  }
};

const useStyles = makeStyles(styles);

export default function Musics()
{
  const [data, setData] = useState({
    totalPages: 0,
    musics: []
  })
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const classes = useStyles();
  const alert = useAlert()

  const getAll = async () =>
  {
    {

      try
      {
        const res = await WebService.get('media', {
          params: {
            page: page, search: search
          }
        })

        setData(res.data)
      }
      catch (err)
      {
        if (err.response)
        {
          return console.log(err.response)
        }

        console.log(err)
      }
    }
  }

  const handleDelete = async (id) =>
  {
    try
    {
      const res = await WebService.delete(`media/${id}`)

      alert.success('Şarkı silindi')
      getAll()
    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      if (err.response)
      {
        return console.log(err.response)
      }

      console.log(err)
    }
  }

  useEffect(() =>
  {
    setPage(1)
    getAll()
  }, [search])

  useEffect(() =>
  {
    getAll()
  }, [page])

  return (
    <>
      <NewMusic getAll={getAll} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <h4 className={classes.cardTitleWhite}>Müzikler</h4>
                <CustomInput
                  value={search}
                  labelText="Ara"
                  id="ara"
                  inputProps={{
                    onChange: (e) => setSearch(e.target.value),
                    style: { color: 'white' }
                  }}
                  formControlProps={{
                    fullWidth: false,
                    style: { margin: 'unset' }
                  }}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <MusicTable
                tableHeaderColor="primary"
                tableHead={["Ad", "Sanatcı", "Süre", "Yükleme Tarihi", ""]}
                tableData={data.musics}
                handleDelete={handleDelete}
              />
              <Box mt={2} display='flex' justifyContent='center'>
                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
              </Box>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

function NewMusic({ getAll })
{
  const [file, setFile] = useState()
  const [values, setValues] = useState({ name: '', artist: '' })
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const alert = useAlert()
  const classes = useStyles();

  const aws = useSelector(state => state.auth.aws)
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });

  const onDrop = useCallback(acceptedFiles =>
  {
    const reader = new FileReader()

    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'audio/mpeg,audio/wav',
    onDrop,
    maxFiles: 1
  })

  const upload = async () =>
  {
    if (!file || !values.name || !values.artist) return
    setUploading(true)
    try
    {
      const formData = new FormData();

      formData.append('file', file)
      formData.append('name', values.name)
      formData.append('artist', values.artist)
      /*
            const params = {
              ACL: 'public-read',
              Body: blob,
              Bucket: S3_BUCKET,
              Key: fileLocation
            };
      */
      //const command = new PutObjectCommand(params)

      // await client.send(command)

      await WebService.post('media', formData, {
        onUploadProgress: function (progressEvent)
        {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percentCompleted)
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      })


      alert.success('Şarkı yüklendi')
      getAll()
      setFile()
      setValues({ name: null, artist: null })

    }
    catch (err)
    {
      alert.error('Bilinmeyen hata')

      console.log(err)
    }
    finally
    {
      setUploading(false)
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card style={{ height: 300 }} profile>
            <CardBody style={{ marginTop: 0 }} profile>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  file ?
                    (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                      {
                        uploading ? (
                          <>
                            {
                              progress == 100 ? (
                                <>
                                  <CircularProgress />
                                  <p style={{ marginTop: 10 }}>İşleniyor</p>
                                </>
                              ) : (
                                <div style={{width:'100%'}}>
                                  <LinearProgressWithLabel value={progress} />
                                </div>
                              )
                            }
                          </>
                        ) : (
                          <>
                            <MusicNoteIcon />
                            <p>{file.name}</p>
                          </>
                        )
                      }

                    </Box>)
                    :
                    (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                      <PublishIcon />
                      <p>Dosyayı bu alana bırakın yada bilgisayarınızdan seçin</p>
                    </Box>
                    )
                }
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Yeni Şarkı</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    value={values.name}
                    labelText="Parça Adı"
                    id="name"
                    inputProps={{
                      onChange: (e) => setValues({ ...values, name: e.target.value })
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    value={values.artist}
                    labelText="Sanatcı"
                    id="artist"
                    inputProps={{
                      onChange: (e) => setValues({ ...values, artist: e.target.value })
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: 'flex-end' }}>
              <Button disabled={uploading} color="primary" onClick={() => upload()} >Yükle</Button>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}