import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, IconButton, Menu, MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function BlogTranslationTable(props)
{

  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              <TableCell colSpan={2} align="center">
                TR
              </TableCell>
              <TableCell colSpan={2} align="center">
                EN
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key} handleClick={props.handleClick} />
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data,handleClick })
{
  const classes = useStyles();

  return (
    <TableRow onClick={() => handleClick(data._id)} className={classes.tableBodyRow} style={{cursor:'pointer'}} >
      <TableCell className={classes.tableCell} >
        {data.title}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data.shortDesc}
      </TableCell>
      <TableCell className={classes.tableCell} >
      {data.locales.length > 0 ? data.locales.find(x => x.locale == 'en').title : ''}
      </TableCell>
      <TableCell className={classes.tableCell} >
      {data.locales.length > 0 ? data.locales.find(x => x.locale == 'en').shortDesc : ''}
      </TableCell>
    </TableRow>
  )
}