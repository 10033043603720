import React, { useState, useCallback, useEffect,useRef } from 'react'
import { Box, Paper, makeStyles, CircularProgress, Divider, Button, TextField,Modal,Backdrop,Fade } from '@material-ui/core'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { RenderTextField, RenderEditor, RenderCreatableSelect } from 'components/FormFields/'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useDropzone } from 'react-dropzone'
import ImageIcon from '@material-ui/icons/Image';
import { mediaUrl } from 'utils/urls'
import { normalizeNumber } from 'utils/normalize'
import WebService from 'services/WebService'
import { useSelector } from 'react-redux'
import { required } from 'utils/validation'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const S3_BUCKET = 'vamanos';
const REGION = 'eu-central-1';



const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};
const useStyles = makeStyles(styles);

const NewRecord = (props) =>
{
    const classes = useStyles();
    const [image, setImage] = useState('')
    const [tags, setTags] = useState({
        genres: [],
        years: [],
        other: []
    })
    const [sides, setSides] = useState([])
    const state = useSelector(state => state)
    const alert = useAlert()
    const history = useHistory()

    const getTags = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: tagsQuery
            })

            if (res.data.shop)
            {
                const tags = res.data.shop.productTags.edges
                let genres = []
                let years = []
                let other = []

                tags.forEach(tag =>
                {
                    if (tag.node.substring(0, 12) == 'vinly_genre_')
                    {

                        return genres.push({ value: tag.node.substring(12, tag.node.length), label: tag.node.substring(12, tag.node.length) })
                    }

                    if (tag.node.substring(0, 11) == 'vinly_year_')
                    {
                        return years.push({ value: tag.node.substring(11, tag.node.length), label: tag.node.substring(11, tag.node.length) })
                    }

                    if (tag.node.substring(0, 6) != 'vinly_tag_')
                    {
                        return other.push({ value: tag.node, label: tag.node })
                    }
                })

                setTags({ genres, years, other })

            }


        }
        catch (err)
        {
            console.log(err)
        }
    }

    const createRecord = async () =>
    {
        if (!props.valid || !image) return

        try
        {
            const selector = formValueSelector('newRecord')
            const values = selector(state, 'productTitle', 'productDescription', 'productSeoTitle', 'productSeoDesc', 'productPrice', 'productGenre', 'productYear', 'productTags', 'metaArtist', 'metaType', 'metaSize', 'metaReleaseYear', 'metaAlbum')

            let tagsCombined = []

            if (values.productGenre?.length > 0) values.productGenre.forEach(genre => tagsCombined.push(`vinly_genre_${genre.value}`))
            if (values.productYear?.length > 0) values.productYear.forEach(year => tagsCombined.push(`vinly_year_${year.value}`))
            if (values.productTags?.length > 0) values.productTags.forEach(tags => tagsCombined.push(`vinly_tag_${tags.value}`))

            let sideObject = {}

            if (sides.length > 0)
            {
                sides.forEach(side =>
                {
                    sideObject[side.side] = side.value.split(',')
                })
            }

            let productData = {
                descriptionHtml: values.productDescription,
                productType: 'vinly',
                seo: {
                    title: values.productSeoTitle,
                    description: values.productSeoDesc
                },
                title: values.productTitle,
                status: 'ACTIVE',
                tags: tagsCombined,
                metafields: [
                    {
                        namespace: 'vinly',
                        key: 'details',
                        valueType: 'JSON_STRING',
                        value: JSON.stringify({
                            artist: values.metaArtist,
                            album: values.metaAlbum,
                            type: values.metaType,
                            size: values.metaSize,
                            release_date: values.metaReleaseYear,
                            sides: sideObject
                        })
                    }
                ],
                variants: [
                    {
                        price: values.productPrice
                    }
                ],
                images: [
                    {
                        src: image
                    }
                ]
            }

            const res = await WebService.post('graphql', {
                query: productCreateQuery,
                variables: {
                    input: productData
                }
            })

            alert.success('Ürün oluşturuldu.')

            history.push('/vinyl')
        }
        catch (err)
        {
            alert.error('Bilinmeyen hata.')
            console.log(err)
        }
    }

    useEffect(() =>
    {
        getTags()
    }, [])

    const addSide = () =>
    {
        let newSides = Array.from(sides)
        newSides.push({ side: sideKeys[sides.length], value: '' })
        setSides(newSides)
    }

    const removeSide = (index) =>
    {
        let arr = Array.from(sides)
        const [removed] = arr.splice(index, 1)

        for (let i = 0; i < arr.length; i++)
        {
            arr[i].side = sideKeys[i]
        }

        setSides(arr)
    }

    const handleSideDataChange = (value, i) =>
    {
        let arr = Array.from(sides)

        arr[i].value = value

        setSides(arr)
    }

    return (
        <Box display='flex' justifyContent='center'>
            <GridContainer style={{ maxWidth: 1000, width: '100%' }}>
                <GridItem xs={12} md={10} >
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Yeni Plak</h4>
                        </CardHeader>
                        <CardBody>
                            <Box>
                                <h4>Genel</h4>
                                <Box>
                                    <Field name="productTitle" component={RenderTextField} label="Ürün Adı" variant="outlined" fullWidth validate={[required]} />
                                </Box>
                                <Box mt={4}>
                                    <Field name="productDescription" component={RenderEditor} validate={[required]} />
                                </Box>
                            </Box>
                            <Divider orientation='horizontal' style={{ width: '100%', marginTop: 35 }} />
                            <Box>
                                <h4>Plak Resmi</h4>
                                <UploadMedia image={image} setImage={setImage} />
                            </Box>
                            <Divider orientation='horizontal' style={{ width: '100%', marginTop: 35 }} />
                            <Box>
                                <h4>Seo</h4>
                                <Box display='flex' justifyContent='space-between'>
                                    <Field name="productSeoTitle" component={RenderTextField} fullWidth label="Başlık" variant="outlined" validate={[required]} />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="productSeoDesc" component={RenderTextField} fullWidth multiline rows={3} label="Açıklama" variant="outlined" validate={[required]} />
                                </Box>
                            </Box>
                            <Divider orientation='horizontal' style={{ width: '100%', marginTop: 35 }} />
                            <Box>
                                <h4>Fiyat</h4>
                                <Box display='flex' justifyContent='space-between'>
                                    <Field normalize={normalizeNumber} name="productPrice" component={RenderTextField} label="Ürün Fiyatı" variant="outlined" />
                                </Box>
                            </Box>
                            <Divider orientation='horizontal' style={{ width: '100%', marginTop: 35 }} />
                            <Box>
                                <h4>Ürün Bilgileri</h4>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="productGenre" component={RenderCreatableSelect} isMulti label="Tür" dataSet={tags.genres} variant="outlined" fullWidth />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="productYear" component={RenderCreatableSelect} isMulti label="Dönem" dataSet={tags.years} variant="outlined" fullWidth />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="productTags" component={RenderCreatableSelect} isMulti label="Etiketler" dataSet={tags.other} variant="outlined" fullWidth />
                                </Box>
                            </Box>
                            <Divider orientation='horizontal' style={{ width: '100%', marginTop: 35 }} />
                            <Box>
                                <h4>Ek Bilgiler</h4>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="metaArtist" component={RenderTextField} label="Sanatçı" variant="outlined" fullWidth validate={[required]} />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="metaAlbum" component={RenderTextField} label="Albüm" variant="outlined" fullWidth validate={[required]} />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="metaType" component={RenderTextField} label="Plak Tipi" variant="outlined" fullWidth />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="metaSize" component={RenderTextField} label="Boyut" variant="outlined" fullWidth />
                                </Box>
                                <Box display='flex' justifyContent='space-between' mt={4}>
                                    <Field name="metaReleaseYear" component={RenderTextField} label="Yayın Tarihi" variant="outlined" fullWidth />
                                </Box>
                            </Box>
                            <Divider orientation='horizontal' style={{ width: '100%', marginTop: 35 }} />
                            <Box>
                                <h4>İçerik</h4>
                                {
                                    sides.map((side, i) => (
                                        <Box key={i} display='flex' justifyContent='space-between' mt={4}>
                                            <TextField
                                                variant='outlined'
                                                label={`${side.side} Yüzü`}
                                                placeholder="İçerikleri ',' ile ayırarak giriniz"
                                                value={sides[i].value}
                                                onChange={(e) => handleSideDataChange(e.target.value, i)}
                                                fullWidth
                                            />
                                            <Button style={{ marginLeft: 50 }} variant='contained' onClick={() => removeSide(i)}>Sil</Button>
                                        </Box>
                                    ))
                                }
                                <Box mt={2}>
                                    <Button variant='contained' onClick={() => addSide()}>Yüz Ekle</Button>
                                </Box>
                            </Box>

                            <Box display='flex' justifyContent='flex-end' mt={6}>
                                <Button color='primary' disabled={!props.valid || !image} variant='contained' onClick={() => createRecord()}>Oluştur</Button>
                            </Box>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Box>

    )
}

const useUploadMediaStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    cropArea: {
        width: 'auto',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 900
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const UploadMedia = ({ image, setImage }) =>
{
    const styles = useUploadMediaStyles()
    const [uploading, setUploading] = useState(false)
    const [openCrop, setOpenCrop] = useState(false)

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 80, aspect: 1 / 1 });

    const aws = useSelector(state => state.auth.aws)
  const client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: aws.accessKeyId,
      secretAccessKey: aws.secretAccessKey
    }
  });

    const onLoad = useCallback(img =>
    {
        imgRef.current = img;
    }, []);

    const upload = async (file) =>
    {
        try
        {
            const params = {
                ACL: 'public-read',
                Body: file,
                Bucket: S3_BUCKET,
                Key: 'records/' + file.name,
                ContentType: file.type
            };

            const command = new PutObjectCommand(params)

            const res = await client.send(command)

            setImage(mediaUrl + 'records/' + file.name)
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const onDrop = useCallback((acceptedFiles) =>
    {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () =>
        {
            setUpImg({ name: acceptedFiles[0].name, file: reader.result })
            setOpenCrop(true)
        }

        reader.readAsDataURL(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/png,image/jpeg',
        onDrop,
        maxFiles: 1
    })

    const endCrop = () =>
    {
        const image = new Image();
        image.src = upImg.file

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / imgRef.current.width;
        const scaleY = image.naturalHeight / imgRef.current.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext("2d");

        const sx = crop.x * scaleX
        const sy = crop.y * scaleY
        const sWidth = crop.width * scaleX
        const sHeight = crop.height * scaleY
        const width = crop.width * scaleX
        const height = crop.height * scaleY

        ctx.drawImage(
            image,
            sx,
            sy,
            sWidth,
            sHeight,
            0,
            0,
            width,
            height
        )

        const reader = new FileReader()
        canvas.toBlob(blob =>
        {
            reader.readAsDataURL(blob)

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () =>
            {
                setOpenCrop(false)
                upload(dataURLtoFile(reader.result, `${Date.now()}.jpg`))
            }
        })
    }

    return (
        <>
            <div style={{ border: '1px solid gray', borderRadius: 4 }} {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    image ?
                        (<Box style={{ minHeight: 280, padding: 50 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <img src={image} style={{ width: '100%' }} />
                        </Box>)
                        :
                        (<Box style={{ height: 280 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            {
                                uploading ? <CircularProgress /> : (
                                    <>
                                        <ImageIcon />
                                        <p>Resmi bu alana bırakın yada bilgisayarınızdan seçin</p>
                                    </>
                                )
                            }

                        </Box>
                        )
                }
            </div>
            <Modal
                aria-labelledby='Media Zone'
                aria-describedby='Media Crop'
                open={openCrop}
                onClose={() => setOpenCrop(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={styles.modal}
            >
                <Fade in={openCrop}>
                    <Paper className={styles.paper}>
                        <Box p={2}>
                            <ReactCrop
                                src={upImg?.file}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={c => setCrop(c)}
                                className={styles.cropArea}
                                imageStyle={{ maxHeight: '85vh' }}
                            />
                        </Box>
                        <Box p={2} pt={0} display='flex' justifyContent='center'>
                            <Button variant='contained' onClick={endCrop} >Tamam</Button>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
        </>
    )
}

const dataURLtoFile = (dataurl, filename) =>
{
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--)
    {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage
}

export default reduxForm({
    form: 'newRecord'
})(NewRecord)

const tagsQuery = `
query {
    shop{
      productTags(first:250){
        edges {
          node
        }
      }
    }
  }`

const productCreateQuery = `
mutation productCreate($input: ProductInput!) {
    productCreate(input: $input) {
      product {
        id
      }
      shop {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`

const sideKeys = ['A', 'B', 'C', 'D', 'E', 'F']