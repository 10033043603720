import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, Dialog, Paper, Grid, Typography, TextField } from "@material-ui/core";
// core components
import ProductVariantTranslationTable from "components/Table/ProductVariantTranslationTable";
import WebService from 'services/WebService'
import { useAlert } from 'react-alert'

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
        padding: 10
    },
    modal: {
        backgroundColor: 'transparent'
    },
    modalPaper: {
        maxWidth: 1080,
        width: '100%',
        backgroundColor: 'white'
    },
    dialogRoot: {
        backgroundColor: 'transparent',
        alignItems: 'center',
        paddingTop: 50
    }
}));

const ProductTab = (props) =>
{
    const styles = useStyles()
    const [data, setData] = useState({
        edges: []
    })
    const [modal, setModal] = useState(false)
    const [values, setValues] = useState({
        title: '',
        option1: '',
        option2: '',
        option3: ''
    })
    const alert = useAlert()

    const getData = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: translatableResourcesQuery,
                variables: {
                    "resourceType": "PRODUCT_VARIANT",
                    "locale": "en",
                    "after": null,
                    "before": null,
                    "first": 10
                }
            })

            if (res.status == 200)
            {
                setData(res.data.translatableResources)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const fetchNextPage = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: translatableResourcesQuery,
                variables: {
                    "resourceType": "PRODUCT_VARIANT",
                    "locale": "en",
                    "after": data.edges[data.edges.length - 1].cursor,
                    "before": null,
                    "first": 10
                }
            })

            if (res.status == 200)
            {
                setData(res.data.translatableResources)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const fetchPrevPage = async () =>
    {
        try
        {
            const res = await WebService.post('graphql', {
                query: translatableResourcesQuery,
                variables: {
                    "resourceType": "PRODUCT_VARIANT",
                    "locale": "en",
                    "after": null,
                    "before": data.edges[0].cursor,
                    "last": 10
                }
            })

            if (res.status == 200)
            {
                setData(res.data.translatableResources)
            }
        }
        catch (err)
        {
            console.log(err)
        }
    }

    const handleClick = (cursor) =>
    {
        const modalData = data.edges.find(x => x.cursor == cursor)

        if(modalData.node.translatableContent.find(x => x.key == 'title').value == 'Default Title') {
            return alert.info('Varyant mevcut değil')
        }


        setModal(modalData)
        setValues({
            title: modalData.node.translations?.find(x => x.key == 'title')?.value ? modalData.node.translations?.find(x => x.key == 'title')?.value : '',
            option1: modalData.node.translations?.find(x => x.key == 'option1')?.value ? modalData.node.translations?.find(x => x.key == 'option1')?.value : '',
            option2: modalData.node.translations?.find(x => x.key == 'option2')?.value ? modalData.node.translations?.find(x => x.key == 'option2')?.value : '',
            option3: modalData.node.translations?.find(x => x.key == 'option3')?.value ? modalData.node.translations?.find(x => x.key == 'option3')?.value : '',
        })
    }

    const handleClose = () =>
    {
        setModal(false)
        setValues({
            title: '',
            option1: '',
            option2: '',
            option3: ''
        })
    }

    const saveTranslations = async () =>
    {
        try
        {
            const resourceId = modal.node.resourceId
            let variables = {
                resourceId: resourceId,
                translations: []
            }

            if (values.title)
            {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "title",
                        value: values.title,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'title').digest
                    })
            }


            if (values.option1)
            {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "option1",
                        value: values.option1,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'option1').digest
                    })
            }


            if (values.option2)
            {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "option2",
                        value: values.option2,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'option2').digest
                    })
            }


            if (values.metaDescription)
            {
                variables.translations.push(
                    {
                        locale: "en",
                        key: "option3",
                        value: values.option3,
                        translatableContentDigest: modal.node.translatableContent.find(x => x.key == 'option3').digest
                    })
            }

            const res0 = await WebService.post('graphql', {
                query: translationsRegisterQuery,
                variables: variables
            })

            if (res0.status == 200)
            {
                const res1 = await WebService.post('graphql', {
                    query: translatableResourceQuery,
                    variables: {
                        resourceId: resourceId,
                        locale: 'en'
                    }
                })

                let newData = data

                newData.edges.find(x => x.node.resourceId == resourceId).node.translatableContent = res1.data.translatableResource.translatableContent
                newData.edges.find(x => x.node.resourceId == resourceId).node.translations = res1.data.translatableResource.translations

                setData(newData)

                alert.success('Çeviri kaydedildi.')
                handleClose()
            }
        }
        catch (err)
        {
            alert.error('Bilinmeyen hata')
            console.log(err)
        }
    }

    useEffect(() =>
    {
        getData()
    }, [])

    return (
        <>
            <Dialog
                open={modal ? true : false}
                onClose={() => handleClose()}
                className={styles.modal}
                fullScreen
                classes={{ paper: styles.dialogRoot }}
            >
                <Box padding={4} className={styles.modalPaper}>
                    {
                        modal && (
                            <Box   >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Başlık</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'title').value}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        disabled={modal.node.translatableContent.find(x => x.key == 'title').value == 'Default Title' ? true : false}
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Başlık'
                                                        value={values.title}
                                                        onChange={(e) => setValues({ ...values, title: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Seçenek 1</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'option1').value}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        disabled={!modal.node.translatableContent.find(x => x.key == 'option1').value}
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Seçenek 1'
                                                        value={values.option1}
                                                        onChange={(e) => setValues({ ...values, option1: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Seçenek 2</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'option2').value}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        disabled={!modal.node.translatableContent.find(x => x.key == 'option2').value}
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Seçenek 2'
                                                        value={values.option2}
                                                        onChange={(e) => setValues({ ...values, option2: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography variant='h6'>Seçenek 3</Typography>
                                        <Box mt={3}>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Tr'
                                                        disabled={true}
                                                        value={modal.node.translatableContent.find(x => x.key == 'option3').value}
                                                        variant='outlined'
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        disabled={!modal.node.translatableContent.find(x => x.key == 'option3').value}
                                                        label='En'
                                                        variant='outlined'
                                                        fullWidth
                                                        placeholder='Seçenek 3'
                                                        value={values.option3}
                                                        onChange={(e) => setValues({ ...values, option3: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    }
                    <Box mt={6} display='flex' justifyContent='space-between'>
                        <Button onClick={() => handleClose()} variant="contained" color="secondary">İptal</Button>
                        <Button onClick={() => saveTranslations()} variant="contained" color="primary">Kaydet</Button>
                    </Box>
                </Box>
            </Dialog>
            <ProductVariantTranslationTable
                tableHeaderColor="primary"
                tableHead={["Başlık", "Seçenek 1", "Seçenek 2", "Seçenek 3", "Başlık", "Seçenek 1", "Seçenek 2", "Seçenek 3"]}
                tableData={data.edges}
                handleClick={handleClick}
            />
            <Box mt={4} display='flex' justifyContent='center' >
                <Button disabled={!data.pageInfo?.hasPreviousPage} onClick={() => fetchPrevPage()} variant='contained'>Önceki Sayfa</Button>
                <Button disabled={!data.pageInfo?.hasNextPage} style={{ marginLeft: 40 }} onClick={() => fetchNextPage()} variant='contained'>Sonraki Sayfa</Button>
            </Box>
        </>
    )
}

export default ProductTab

const translatableResourcesQuery = `query translatableResources($first:Int,$last:Int,$resourceType:TranslatableResourceType!,$locale:String!,$after:String,$before:String) {
    translatableResources(first:$first,last:$last,resourceType:$resourceType,after:$after,before:$before){
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          resourceId
          translatableContent {
            key
            locale
            digest
            value
          }
          translations(locale:$locale){
            key
            locale
            outdated
            value
          }
        }
      }
    }
  }`

const translatableResourceQuery = `
  query translatableResource($resourceId: ID!,$locale:String!){
    translatableResource(resourceId:$resourceId)
    {
          resourceId
          translatableContent {
            key
            digest
            value
            locale
          }
          translations(locale:$locale) {
            value
            key
            locale
            outdated
          }
          
        }
  }`

const translationsRegisterQuery = `
  mutation translationsRegister($resourceId: ID!, $translations: [TranslationInput!]!) {
    translationsRegister(resourceId: $resourceId, translations: $translations) {
      translations {
        key
        locale
        outdated
        value
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  `