import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuth: false,
    aws:{
        accessKeyId: '',
        secretAccessKey: ''
    },
    token: '',
    user: {
        _id: '',
        username: ''
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action)
        {
            state.user = action.payload
        },
        setToken(state, action)
        {
            state.token = `Bearer ${action.payload}`
        },
        setAuth(state, action)
        {
            state.isAuth = true
        },
        handleLogin(state, action)
        {
            state.isAuth = true
            state.user = action.payload.user
            state.token = `Bearer ${action.payload.token}`
            state.aws = action.payload.aws
        },
        reset: state => initialState
    }
})

export const {
    setUser,
    setToken,
    setAuth,
    reset,
    handleLogin
} = authSlice.actions