import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import {useSelector} from 'react-redux'
import Login from './views/Login/Login'

// core components
import Admin from "layouts/Admin.js";

const hist = createBrowserHistory();

const App = (props) =>
{
    const isAuth = useSelector(state => state.auth.isAuth)

    if (!isAuth)
    {
        return (
            <Login />
        )
    }

    return (
        <Router history={hist}>
            <Switch>
                <Route path="/" component={Admin} />
            </Switch>
        </Router>
    );


}

export default App