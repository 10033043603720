import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, Tabs, Tab } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import WebService from 'services/WebService'
import { useAlert } from 'react-alert'
import GeneralTab from './GeneralTab'
import ProductTab from './ProductTab'
import VariantTab from './VariantTab'
import BlogTab from './BlogTab'
import ActivityTab from './ActivityTab'

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        color: 'white'
    }
};

const useStyles = makeStyles(styles);

const Translations = (props) =>
{
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) =>
    {
        setValue(newValue);
    };

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>Yerelleştirme</h4>
                            </Box>
                        </CardHeader>
                        <CardBody>
                            <Box mt={3}>
                                <Tabs  value={value} onChange={handleChange}>
                                    <Tab label="Genel" />
                                    <Tab label="Ürünler" />
                                    <Tab label="Varyantlar" />
                                    <Tab label="Bloglar" />
                                    <Tab label="Etkinlikler" />
                                </Tabs>
                            </Box>
                            <Box mt={6}>
                                <TabPanel value={value} index={0}><GeneralTab/></TabPanel>
                                <TabPanel value={value} index={1}><ProductTab /></TabPanel>
                                <TabPanel value={value} index={2}><VariantTab /></TabPanel>
                                <TabPanel value={value} index={3}><BlogTab /></TabPanel>
                                <TabPanel value={value} index={4}><ActivityTab /></TabPanel>
                            </Box>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default Translations

function TabPanel(props)
{
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}