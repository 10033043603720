import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, IconButton, Menu, MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import { mediaUrl } from 'utils/urls'
import {useHistory} from 'react-router-dom'
import { DateTime } from "luxon";

const useStyles = makeStyles(styles);

export default function RecordsTable(props)
{

  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key}/>
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data })
{
  const classes = useStyles();
  const history = useHistory()

  const handleEdit = () => {
    history.push(`/vinyl/edit/${data.node.legacyResourceId}`)
  }

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell  onClick={() => handleEdit()} style={{cursor:'pointer'}} className={classes.tableCell} >
        <img style={{width:150}} src={data.node.featuredImage.transformedSrc} />
      </TableCell>
      <TableCell onClick={() => handleEdit()} style={{cursor:'pointer'}} className={classes.tableCell} >
        {data.node.title}
      </TableCell>
      <TableCell onClick={() => handleEdit()} style={{cursor:'pointer'}} className={classes.tableCell} >
        {data.node.priceRangeV2.minVariantPrice.amount} - {data.node.priceRangeV2.maxVariantPrice.amount} {data.node.priceRangeV2.maxVariantPrice.currencyCode}
      </TableCell>
      <TableCell onClick={() => handleEdit()} style={{cursor:'pointer'}} className={classes.tableCell} >
      {DateTime.fromISO(data.node.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }
      </TableCell>
    </TableRow>
  )
}