import React, { useState } from 'react'
import axios from 'axios'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { Container } from '@material-ui/core'
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { handleLogin } from '../../slices/authSlicer'
import {apiUrl} from '../../utils/urls'
import { useSelector, useDispatch } from 'react-redux'

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

const Login = (props) =>
{
    const [values, setValues] = useState({ username: '', password: '' })
    const classes = useStyles();
    const dispatch = useDispatch()

    const login = async () => {
        try {
          const res = await axios.post(`${apiUrl}/auth/login`,values)
          localStorage.setItem('token',res.data.token)
          dispatch(handleLogin(res.data))
        }
        catch(err)
        {
          if(err.response) {
            return console.log(err.response)
          }
    
          console.log(err)
        }
      }

      const setPassword =(e) => {
          setValues({...values,password:e.target.value})
      }

      const setUsername = (e) => {
        setValues({...values,username:e.target.value})
      }

      const handleKeyUp = (e) =>
    {
        if (e.key === 'Enter')
        {
            login()
        }
    }

    return (
        <Container style={{ display: 'flex', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Vamonos Records & Coffee</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        value={values.username}
                                        labelText="E-posta"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                       inputProps={{
                                            onChange:setUsername
                                       }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        value={values.password}
                                        labelText="Şifre"
                                        id="password"
                                        inputProps={{
                                            type: "password",
                                            autoComplete: "current-password",
                                            onChange:setPassword,
                                            onKeyUp:handleKeyUp
                                        }}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                        </CardBody>
                        <CardFooter style={{ justifyContent: 'flex-end' }}>
                            <Button onClick={() => login()} color="primary">Giriş Yap</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </Container>
    )
}

export default Login