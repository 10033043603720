import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, IconButton, Menu, MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import { DateTime } from "luxon";

const useStyles = makeStyles(styles);

export default function MusicTableForPlaylist(props)
{
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key} handleAddToPlaylist={props.handleAddToPlaylist} songs={props.songs} />
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data,handleAddToPlaylist,songs })
{
  const classes = useStyles();

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell} >
        {data.name}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data.artist}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data.duration}
      </TableCell>
      <TableCell className={classes.tableCell} >
      {DateTime.fromISO(data.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }
      </TableCell>
      <TableCell className={classes.tableCell} >
        <IconButton disabled={songs.find(x => x._id == data._id) ? true : false} onClick={()=>handleAddToPlaylist(data._id)} size="small" >
          <AddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
