import React from "react";
import ReactDOM from "react-dom";
import App from './app'
import { initialize } from './initialize'
import { Provider } from 'react-redux'
import store from './store'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'components/Alert/Alert.js'

import "assets/css/material-dashboard-react.css?v=1.9.0";

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
      zIndex: 1600
  }
}

initialize(async () =>
{
  ReactDOM.render(
    <AlertProvider template={AlertTemplate} {...alertOptions}>
    <Provider store={store}>
      <App />
    </Provider>
    </AlertProvider>,
    document.getElementById('root')
  );

  if (process.env.NODE_ENV === 'production')
  {
    console.log(
      `Vamanos Cafe`
    )
  }
})

