import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, IconButton, Menu, MenuItem, Typography, ListItemIcon } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import {mediaUrl} from 'utils/urls'
import { DateTime } from "luxon";

const useStyles = makeStyles(styles);

export default function SlideTable(props)
{
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key} handleDelete={props.handleDelete} />
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data,handleDelete })
{
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) =>
  {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () =>
  {
    setAnchorEl(null);
  };

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell} >
        <img src={mediaUrl + data.url} style={{height:100}} />
      </TableCell>
      <TableCell className={classes.tableCell} >
      {DateTime.fromISO(data.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED) }
      </TableCell>
      <TableCell className={classes.tableCell} >
        <IconButton onClick={handleClick} size="small" >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/*
          <MenuItem>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Düzenle</Typography>
          </MenuItem>
          */}
          <MenuItem onClick={() => handleDelete(data._id)}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Sil</Typography>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
}