/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import MusicNoteIcon from '@material-ui/icons/MusicNote';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Stream from 'views/Stream/Stream.js'
import Typography from "views/Typography/Typography.js";
import Musics from "views/Musics/Musics.js"
import Playlists from "views/Playlists/Playlists.js"
import PlaylistDetail from "views/Playlists/PlaylistDetail"
import Radio from "views/Radio/Radio.js"
import RadioIcon from '@material-ui/icons/Radio';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import AlbumIcon from '@material-ui/icons/Album';
import DescriptionIcon from '@material-ui/icons/Description';
import Records from 'views/Record/Records'
import NewRecord from 'views/Record/NewRecord'
import UpdateRecord from 'views/Record/UpdateRecord'
import Blogs from 'views/Blog/Blogs'
import EditBlog from 'views/Blog/EditBlog'
import NewBlog from 'views/Blog/NewBlog'
import SlideShow from 'views/SlideShow/SlideShow'
import Users from 'views/Users/Users'
import UserDetail from 'views/Users/UserDetail'
import PersonIcon from '@material-ui/icons/Person';
import TranslateIcon from '@material-ui/icons/Translate';
import Translations from 'views/translations/Translations'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Contracts from 'views/Contracts/Contracts'
import AssignmentIcon from '@material-ui/icons/Assignment';
import Note from 'views/Note/Note'
import Activities from 'views/Activity/Activities'
import ActivityView from 'views/Activity/ActivityView'
import NewActivity from 'views/Activity/NewActivity'
import UpdateActivity from 'views/Activity/UpdateActivity'
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Customers from 'views/Customers/Customers'
import PeopleIcon from '@material-ui/icons/People';
import ContactPage from 'views/ContactPage/ContactPage'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MessageIcon from '@material-ui/icons/Message';
import Contacts from 'views/Contact/Contacts'
import ContactDetail from 'views/Contact/ContactDetail'

const dashboardRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/",
    sideBar:true
  },
  {
    path: "slideshow",
    name: "Slide Show",
    component: SlideShow,
    layout: "/",
    icon: PhotoLibraryIcon,
    sideBar:true
  },
  {
    path: "vinyl/new",
    name: "Yeni Plak",
    component: NewRecord,
    layout: "/",
    sideBar:false
  },
  {
    path: "vinyl/edit/:id",
    name: "Yeni Plak",
    component: UpdateRecord,
    layout: "/",
    sideBar:false
  },
  {
    path: "vinyl",
    name: "Plaklar",
    icon: AlbumIcon,
    component: Records,
    layout: "/",
    sideBar:true
  },
  {
    path: "radio",
    name: "Radyo",
    icon: RadioIcon,
    component: Radio,
    layout: "/",
    sideBar:true
  },
  {
    path: "music",
    name: "Müzikler",
    icon: MusicNoteIcon,
    component: Musics,
    layout: "/",
    sideBar:true
  }
  ,
  {
    path: "playlist/:id",
    name: "Çalma Listesi",
    component: PlaylistDetail,
    layout: "/",
    sideBar:false
  },
  {
    path: "playlist",
    name: "Çalma Listeleri",
    icon: QueueMusicIcon,
    component: Playlists,
    layout: "/",
    sideBar:true
  },
  {
    path: "livestream",
    name: "Canlı Yayın",
    icon: LiveTvIcon,
    component: Stream,
    layout: "/",
    sideBar:true
  },
  {
    path: "blog/new",
    name: "Yeni Blog",
    icon: DescriptionIcon,
    component: NewBlog,
    layout: "/",
    sideBar:false
  },
  {
    path: "blog/edit/:id",
    name: "Blog Düzenle",
    icon: DescriptionIcon,
    component: EditBlog,
    layout: "/",
    sideBar:false
  },
  {
    path: "blog",
    name: "Bloglar",
    icon: DescriptionIcon,
    component: Blogs,
    layout: "/",
    sideBar:true
  },
  {
    path: "users/:id",
    name: "Kullanıcı Profili",
    icon: DescriptionIcon,
    component: UserDetail,
    layout: "/",
    sideBar:false
  },
  {
    path: "users",
    name: "Kullanıcılar",
    icon: PersonIcon,
    component: Users,
    layout: "/",
    sideBar:true
  },
  {
    path: "contracts",
    name: "Sözleşmeler",
    icon: AssignmentIcon,
    component: Contracts,
    layout: "/",
    sideBar:true
  },
  {
    path: "translate",
    name: "Yerelleştirme",
    icon: TranslateIcon,
    component: Translations,
    layout: "/",
    sideBar:true
  },
  {
    path: "note",
    name: "Notlar",
    icon: NoteAddIcon,
    component: Note,
    layout: "/",
    sideBar:true
  },
  {
    path: "activity/new",
    name: "Yeni Etkinlik",
    icon: LocalActivityIcon,
    component: NewActivity,
    layout: "/",
    sideBar:false
  },
  {
    path: "activity/view/:id",
    name: "Etkinlik Görüntüle",
    icon: LocalActivityIcon,
    component: ActivityView,
    layout: "/",
    sideBar:false
  },
  {
    path: "activity/edit/:id",
    name: "Etkinlik Düzenle",
    icon: LocalActivityIcon,
    component: UpdateActivity,
    layout: "/",
    sideBar:false
  },
  {
    path: "activity",
    name: "Etkinlikler",
    icon: LocalActivityIcon,
    component: Activities,
    layout: "/",
    sideBar:true
  },
  {
    path: "customers",
    name: "Müşteriler",
    icon: PeopleIcon,
    component: Customers,
    layout: "/",
    sideBar:true
  },
  {
    path: "contact-page",
    name: "İletişim Sayfası",
    icon: ContactMailIcon,
    component: ContactPage,
    layout: "/",
    sideBar:true
  },
  {
    path: "contact-request/:id",
    name: "İletişim İstekleği Detay",
    icon: MessageIcon,
    component: ContactDetail,
    layout: "/",
    sideBar:false
  },
  {
    path: "contact-request",
    name: "İletişim İstekleri",
    icon: MessageIcon,
    component: Contacts,
    layout: "/",
    sideBar:true
  },
];

export default dashboardRoutes;
