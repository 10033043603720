import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { DateTime } from "luxon";
import { useHistory } from "react-router";

const useStyles = makeStyles(styles);

export default function ContactTable(props)
{
  const classes = useStyles();
  
  const { tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) =>
              {
                if (props.type !== "full" && prop === "Durum")
                {
                  return
                }

                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) =>
          {
            return <Row data={prop} key={key} type={props.type} />
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function Row({ data, type })
{
  const classes = useStyles();
  const history = useHistory()

  return (
    <TableRow onClick={() => history.push(`/contact-request/${data._id}`)} style={{cursor:'pointer'}} className={classes.tableBodyRow}>
      <TableCell className={classes.tableCell} >
        {data.fullName}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {data.email}
      </TableCell>
      <TableCell className={classes.tableCell} >
        {DateTime.fromISO(data.createdAt).setLocale('tr').toLocaleString(DateTime.DATETIME_MED)}
      </TableCell>
      {
        type == 'full' ? (
          <TableCell className={classes.tableCell} style={data.status ? { color:'green'} : { color:'orange'}} >
            {data.status ? 'Cevaplandı' : 'Beklemede'}
          </TableCell>
        ) : <></>
      }
    </TableRow>
  )
}