import React, { useCallback, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Button, Box, TextField } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import WebService from 'services/WebService'
import { useAlert } from 'react-alert'
import Pagination from '@material-ui/lab/Pagination';
import ContactTable from 'components/Table/ContactTable'
import CustomInput from "components/CustomInput/CustomInput.js";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        color: 'white'
    }
};

const useStyles = makeStyles(styles);

export default function Note()
{
    const [data, setData] = useState({
        totalPages: 0,
        contacts: []
    })
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const classes = useStyles();
    const alert = useAlert()

    const getAll = async () =>
    {
        {

            try
            {
                const res = await WebService.get('contact', {
                    params: {
                        page: page, search: search
                    }
                })

                setData(res.data)
            }
            catch (err)
            {
                if (err.response)
                {
                    return console.log(err.response)
                }

                console.log(err)
            }
        }
    }

    useEffect(() =>
    {
        setPage(1)
        getAll()
    }, [search])

    useEffect(() =>
    {
        getAll()
    }, [page])

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <h4 className={classes.cardTitleWhite}>İletişim İstekleri</h4>
                                <CustomInput
                                    value={search}
                                    labelText="Ara"
                                    id="ara"
                                    inputProps={{
                                        onChange: (e) => setSearch(e.target.value),
                                        style: { color: 'white' }
                                    }}
                                    formControlProps={{
                                        fullWidth: false,
                                        style: { margin: 'unset' }
                                    }}
                                />
                            </Box>
                        </CardHeader>
                        <CardBody>
                            <ContactTable
                                tableHead={["Ad Soyad", "E-posta", "İstek Tarihi", "Durum"]}
                                tableData={data.contacts}
                                type="full"
                            />
                            <Box mt={2} display='flex' justifyContent='center'>
                                <Pagination count={data.totalPages} page={page} onChange={(e, value) => setPage(value)} />
                            </Box>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}