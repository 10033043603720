import { store } from './store'
import axios from 'axios'
import { handleLogin } from './slices/authSlicer'
import {apiUrl} from './utils/urls'

export async function initialize(callback)
{
    try
    {
        const token = localStorage.getItem('token')

        if (token)
        {
            const user = await axios.get(`${apiUrl}/auth/verify`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const data = {
                token: token,
                user: user.data.user,
                aws:user.data.aws
            }

            store.dispatch(handleLogin(data))

        }

        return callback();

    }
    catch (err)
    {
        if (err.response)
        {
            console.log(err.response)
        } else
        {
            console.log(err)
        }

        return callback();

    }
}